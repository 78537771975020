<template>
  <el-row class="tableRowModify">
    <!-- ZL -->
    <el-amap-search-box class="search-box1" :search-option="searchOption" :on-search-result="onSearchResult"></el-amap-search-box>
    <el-amap vid="amapDemo_position" :events="events" class="amap-demo" >

    </el-amap>

  </el-row>
</template>

<script>
  import {
    AMapManager
  } from 'vue-amap';
  import {
    lazyAMapApiLoaderInstance
  } from 'vue-amap';
  import {
    addressSetMapCenter
  } from "./location.js"
  import {
    amapSetMarker
  } from "./marker.js"
  import {
    getLonLan
  } from "./common.js"
  export default {
    name: "Amap",
    data() {
      return {
        searchOption: {
          city: '河南省',
          citylimit: true
        },
        //经纬度
        lnglat: {},
        zooms: 14,
        centers: [113.726163, 34.762536],
        events: {
          // init(o) {
          //   let marker = new AMap.Marker({
          //     position: [113.726163, 34.762536]
          //   });

          //   marker.setMap(o);
          // }
        },
      }
    },
    mounted() {

      lazyAMapApiLoaderInstance.load().then(() => {
        // your code ...
        console.log('地图加载成功')
        this.map = new AMap.Map('amapDemo_position', {
          center: this.centers,
          zoom: this.zooms,

        });
        this.map.on("click", (e) => {
          const lonlag = getLonLan(e)
          this.lnglat =lonlag
          // console.log(longlag)
          this.$emit("lonlag", lonlag)
          //设置点覆盖物
          this.setMarker(this.lnglat,this.map)

        })
      });
    },
    methods: {

      onSearchResult(pois) {
        const that = this;
        let latSum = 0;
        let lngSum = 0;
        if (pois.length > 0) {
          pois.forEach(poi => {
            let {
              lng,
              lat
            } = poi;
            lngSum += lng;
            latSum += lat;
            // this.markers.push([poi.lng, poi.lat]);
          });
          let center = {
            lng: lngSum / pois.length,
            lat: latSum / pois.length,

          };
          console.log(center.lng)
          console.log(center.lat)
          this.Center = [center.lng, center.lat];
          this.map = new AMap.Map('amapDemo_position', {
            center: [center.lng, center.lat],
            zoom: 18,

          });
          this.map.on("click", (e) => {
            const lonlag = getLonLan(e)
            this.lnglat=lonlag
             console.log(lonlag)
            this.$emit("lonlag", lonlag)
            this.setMarker(this.lnglat,this.map)
          })
        }
      },
      // setMapCenter(value){
      //   addressSetMapCenter(value,this.map);
      // },
      setMarker(){
        amapSetMarker(this.lnglat,this.map);
      }

    }
  }
</script>

<style scoped="scoped">
  .tableRowModify {

    height: 60vh;
  }

  .search-box1 {
        position: absolute;
        top: 25px;
        left: 20px;

      }
</style>
