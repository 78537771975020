<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>课件管理</span>
    </el-row>
    <el-row class="selectRow">
      <el-button @click="handleadd" class="checkOutBtn">添加课件</el-button>
      <el-button
        @click="handleDeleteSome"
        :disabled="isDisable"
        class="checkOutBtn"
        style="background-color:#fb0103"
      >批量删除</el-button>
    </el-row>

    <el-row class="tableRowModify">
      <el-table
        :data="tableData"
        stripe
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="编号"></el-table-column>
        <el-table-column align="center" prop="title" label="标题">
          <template slot-scope="scope">
            <span :title="scope.row.title" class="overflowDoit">{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="describe" label="简介">
          <template slot-scope="scope">
            <span :title="scope.row.describe" class="overflowDoit">{{scope.row.describe}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="addtime" label="添加时间">
          <template slot-scope="scope">
            <span :title="scope.row.addtime" class="overflowDoit">{{scope.row.addtime}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="400">
          <template slot-scope="scope">
            <el-button @click="handleCheck(scope.row)" class="tableBtn">查看学习进度</el-button>
            <el-button @click="handleEdit(scope.row)" class="tableBtn">编辑</el-button>
            <el-button
              @click="handleRemove(scope.row)"
              class="tableBtn"
              style="background-color:#fb0103"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 新增 -->
    <el-dialog
      :title="`添加课件`"
      class="riskDia"
      :visible.sync="dialogadd"
      width="35%"
      :before-close="dialogaddClose"
      @close="dialogaddClosed"
    >
      <el-row class="tableRowModify">
        <el-form ref="form" :model="addFormData" label-width="50px">
          <el-form-item label="标题" prop="desc">
            <el-input type="textarea" style="width:90%" v-model="addFormData.title"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="desc">
            <el-input style="width:90%" v-model="addFormData.describe"></el-input>
          </el-form-item>
          <el-upload
            style="margin:20px 0 20px 10px"
            ref="addUpLoad"
            class="upload-demo"
            action="/api/Homepage/uploadImg"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
          >
            <el-button size="small" type="primary" style="margin-left:0" class="footerBtn">点击上传</el-button>
            <div
              slot="tip"
              v-if="fileName"
              @mouseover="handleOver"
              @mouseleave="handleLeave"
              class="el-upload__tip addCloseI"
            >
              {{this.fileName}}
              <i
                v-if="seen"
                class="el-icon-circle-close iconClose"
                @click="handleAddClear"
              ></i>
            </div>
          </el-upload>
        </el-form>
      </el-row>
      <el-row class="footerBox" style="margin:20px auto">
        <el-button @click="addFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="handleClearSubmit" class="checkOutBtn footerBtn">清空</el-button>
      </el-row>
    </el-dialog>
    <!-- 新增 -->

    <!-- 编辑 -->
    <el-dialog
      :title="`编辑课件`"
      class="riskDia"
      :visible.sync="dialogedit"
      width="35%"
      :before-close="dialogeditClose"
      @close="dialogeditClosed"
    >
      <el-row class="tableRowModify">
        <el-form ref="form" :model="editFormData" label-width="50px">
          <el-form-item label="标题" prop="desc">
            <el-input style="width:90%" type="textarea" v-model="editFormData.title"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="desc">
            <el-input style="width:90%" v-model="editFormData.describe"></el-input>
          </el-form-item>
          <el-upload
            style="margin:20px 0 20px 10px"
            ref="editUpLoad"
            class="upload-demo"
            action="/api/Homepage/uploadImg"
            :before-upload="editbeforeAvatarUpload"
            :show-file-list="false"
          >
            <el-button size="small" style="margin-left:0" type="primary" class="footerBtn">点击上传</el-button>
            <div
              slot="tip"
              v-if="fileNameEdit"
              @mouseover="handleOver"
              @mouseleave="handleLeave"
              class="el-upload__tip addCloseI"
            >
              {{this.fileNameEdit}}
              <i
                v-if="seen"
                class="el-icon-circle-close iconClose"
                @click="handleeditClear"
              ></i>
            </div>
          </el-upload>
        </el-form>
      </el-row>
      <el-row class="footerBox">
        <el-button @click="editFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="handleClearSubmit1" class="checkOutBtn footerBtn">清空</el-button>
      </el-row>
    </el-dialog>
    <!-- 编辑 -->

    <!-- 课件学习进度 -->
    <el-dialog
      :title="`查看学习进度`"
      class="riskDia"
      :visible.sync="studyVisible"
      width="40%"
      :before-close="studyClose"
      @close="studyClosed"
    >
      <el-row class="tableRow" style="width:100%">
        <el-table border :data="studyData" stripe style="width: 100%">
          <el-table-column align="center" prop="nickname" label="企业名称"></el-table-column>
          <el-table-column align="center" prop="rate" label="学习进度"></el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData.length>0"
          @size-change="handleSizeChangeStudy"
          @current-change="handleCurrentChangeStudy"
          :current-page="currentPageStudy"
          :page-sizes="[3, 5, 10, 15, 20]"
          :page-size="pageSizeStudy"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCountStudy"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 课件学习进度 -->
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data() {
    return {
      checkId: '',
      dataName: '',
      studyVisible: false,
      seen: false,
      fileName: '',
      fileNameEdit: '',
      fileListEdit: [],
      fileList: [],
      // 图片上传
      imageUrl: '',
      editId: '',
      addFormData: {
        title: '',
        describe: '',
        type: '2',
      },
      editFormData: {},
      ids: [],
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 新增
      dialogadd: false,
      // 编辑
      dialogedit: false,
      dangerName: '',
      formData: {
        name: '',
      },
      currentPage: 1,
      totalCount: 0,
      pageSize: 15,
      tableData: [],

      currentPageStudy: 1,
      totalCountStudy: 0,
      pageSizeStudy: 15,
      studyData: [],
    }
  },

  methods: {
    // *点击查看学习进度
    handleCheck(row) {
      this.dataName = row.content
      this.checkId = row.id
      this.getStudyList()
      this.studyVisible = true
    },
    // *查看学习进度
    async getStudyList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPageStudy)
      param.append('length', this.pageSizeStudy)
      param.append('id', this.checkId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/progress',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.studyData = res.datas.list
      this.totalCountStudy = parseInt(res.datas.count)
      console.log('', this.editFormData)
    },
    handleLeave() {
      this.seen = false
    },
    handleOver() {
      this.seen = true
    },
    beforeRemove(file, fileList) {},
    // 图片上传
    async beforeAvatarUpload(file) {
      if (this.addFormData.url) {
        return this.$message.warning('只能上传一个文件')
      }
      console.log(file)
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        // data: param,
        data: param,
      })
      // return console.log('res', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('上传成功')
      this.addFormData.url = res.datas.img_url
      this.fileName = file.name
      // this.fileList.push({
      //   name: file.name,
      //   url: this.addFormData.url,
      // })
      return false
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return isJPG && isLt2M
    },
    // 点击x
    handleAddClear() {
      this.fileName = ''
      this.addFormData.url = ''
    },
    async editbeforeAvatarUpload(file) {
      if (this.editFormData.url) {
        return this.$message.warning('只能上传一个文件')
      }
      console.log(file)
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        // data: param,
        data: param,
      })
      // return console.log('res', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('上传成功')
      this.editFormData.url = res.datas.img_url
      this.fileNameEdit = file.name
      // this.fileList.push({
      //   name: file.name,
      //   url: this.addFormData.url,
      // })
      return false
    },
    // 点击x
    handleeditClear() {
      this.editFormData.url = ''
      this.fileNameEdit = ''
    },
    // *批量删除
    async handleDeleteSome() {
      const isDelete = await this.$confirm('是否批量删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消批量删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', this.ids)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Homepage/newsDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getClassList()
      }
    },
    // *删除
    async handleRemove() {
      const delArr = []
      delArr.push(id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Homepage/newsDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getClassList()
      }
    },
    // *编辑
    async handleEdit(row) {
      this.editId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editFormData = res.datas
      console.log('课件详情(点击编辑)', this.editFormData)
      this.fileNameEdit = res.datas.content

      this.dialogedit = true
    },
    // *编辑提交
    async editFormSubmit() {
      if (this.editFormData.title.trim().length == 0)
        return this.$message.error('请填写标题')
      if (!this.editFormData.url) return this.$message.error('请上传课件')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      param.append('url', this.editFormData.url)
      param.append('describe', this.editFormData.describe)
      param.append('title', this.editFormData.title)
      param.append('content', this.fileNameEdit)
      param.append('style', 2)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)

      this.dialogedit = false
      this.getClassList()
    },
    // *新增提交
    async addFormSubmit() {
      if (this.addFormData.title.trim().length == 0)
        return this.$message.error('请填写标题')
      if (!this.addFormData.url) {
        return this.$message.warning('请上传课件')
      }
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('url', this.addFormData.url)
      param.append('describe', this.addFormData.describe)
      param.append('content', this.fileName)
      param.append('title', this.addFormData.title)
      param.append('style', 2)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)

      this.dialogadd = false
      this.getClassList()
    },
    // *新增
    handleadd() {
      this.dialogadd = true
    },

    handleSelectionChange(selection) {
      let arr = []
      selection.forEach((item) => {
        arr.push(item.id)
      })
      this.ids = [...arr]
    },
    // *删除
    async handleRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Homepage/newsDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getClassList()
      }
    },
    // 获取列表
    async getClassList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('style', 2)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log('课件', res)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    // 富文本
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange({ editor, html, text }) {
      //编辑器文本发生变化
      //this.content可以实时获取到当前编辑器内的文本内容
      // console.log(this.content);
    },
    //清除
    handleClearSubmit() {
      this.addFormData.title = ''
      this.addFormData.describe = ''
      this.fileName = ''
      this.addFormData.url = ''
    },
    //清除
    handleClearSubmit1() {
      this.editFormData = {}
      this.fileNameEdit = ''
    },

    // 新增
    dialogaddClose() {
      this.dialogadd = false
    },
    dialogaddClosed() {
      this.fileName = ''
      this.addFormData.title = ''
      this.addFormData.describe = ''
      this.fileList = []
      this.addFormData.url = ''
      this.addFormData.type = '1'
    },
    // 编辑
    dialogeditClose() {
      this.dialogedit = false
    },
    dialogeditClosed() {
      this.editId = ''
      this.fileNameEdit = ''
      this.editFormData = {}
      this.fileListEdit = {}
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getClassList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getClassList()
    },
    handleSizeChangeStudy(val) {
      this.pageSizeStudy = val
      this.getStudyList()
    },
    handleCurrentChangeStudy(val) {
      this.currentPageStudy = val
      this.getStudyList()
    },
    studyClose() {
      this.studyVisible = false
    },
    studyClosed() {
      this.checkId = ''
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    // this.myHeaders.Authorization = this.token
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getClassList()
  },
  computed: {
    isDisable() {
      if (this.ids.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    max-height: 70vh;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.tableRowModify {
  width: 100%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
/deep/ .quill-editor {
  padding: 0;
}
// 图片上传
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .avatar-uploader-margin {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 120px;
}
.addCloseI {
  font-size: 14px;
}
.addCloseI:hover {
}
.iconClose {
  margin-left: 100px;
  cursor: pointer;
}
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
// 修改textarea高度
/deep/.el-textarea__inner {
  border-radius: 0;
}
/deep/ .modifyInner textarea {
  border-radius: 0 !important;
  height: 100px;
}
</style>
