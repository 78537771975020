<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>学习视频</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>公司名称</span>
        <el-input v-model="searchInfo.company_name" placeholder="请输入内容"></el-input>
      </div>
      <div class="selectCol">
        <span>文件名称</span>
        <el-input v-model="searchInfo.filename" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
    </el-row>

    <el-row class="tableRow">
      <el-table :data="tableData" stripe border style="width: 100%">
        <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
        <el-table-column align="center" prop="filename" label="文件名称">
          <template slot-scope="scope">
            <span :title="scope.row.filename" class="overflowDoit">{{scope.row.filename}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="nickname" label="公司名称">
          <template slot-scope="scope">
            <span :title="scope.row.nickname" class="overflowDoit">{{scope.row.nickname}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="300" label="文件地址">
          <template slot-scope="scope">
            <span
              :title="scope.row.file_url"
              class="overflowDoit linkClick"
              @click="handleLinkClick(scope.row.file_url)"
            >{{scope.row.file_url}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="add_time" label="添加时间">
          <template slot-scope="scope">
            <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="content" label="内容">
          <template slot-scope="scope">
            <span :title="scope.row.content" class="overflowDoit">{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120">
          <template slot-scope="scope">
            <el-button @click="checkOut(scope.row)" class="tableBtn">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 20, 30]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 会议详情 -->
    <el-dialog
      :title="`会议详情`"
      class="riskDia modifyHeight"
      :visible.sync="checkOutDia"
      width="50%"
      :before-close="checkOutDiaClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="checkDetail" label-width="123px">
          <el-form-item label="文件名字">
            <el-input style="width:90%" disabled v-model="checkDetail.filename"></el-input>
          </el-form-item>

          <!-- <el-form-item label="添加时间">
            <el-input style="width:90%" disabled v-model="checkDetail.add_time"></el-input>
          </el-form-item>-->
          <el-form-item label="内容">
            <el-input style="width:90%" type="textarea" disabled v-model="checkDetail.content"></el-input>
          </el-form-item>
          <el-form-item label="文件地址">
            <!-- <el-input
              style="width:90%"
              class="linkClick"
              disabled
              @click="handleLinkClick"
              v-model="checkDetail.file_url"
            ></el-input>-->
            <div
              class="linkClick linkBox"
              @click="handleLinkClick(checkDetail.file_url)"
            >{{checkDetail.file_url}}</div>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 会议详情 -->
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data() {
    return {
      fileList: [],
      dialogaddV: false,

      videoUrl: '',
      // 图片上传
      imageUrl: '',
      editId: '',
      addFormData: {
        sort: '',
        name: '',
        url: '',
      },
      editFormData: {},
      ids: [],
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 新增
      dialogadd: false,
      // 编辑
      dialogedit: false,
      dangerName: '',
      formData: {
        name: '',
      },
      checkDetail: {},
      currentPage: 1,
      totalCount: 0,
      pageSize: 20,
      tableData: [],
      checkOutId: '',
      searchInfo: {
        company_name: '',
        filename: '',
      },
      checkOutDia: false,
    }
  },

  methods: {
    handleLinkClick(item) {
      let fileName = item.lastIndexOf('.') //取到文件名开始到最后一个点的长度
      let fileNameLength = item.length //取到文件名长度
      let fileFormat = item.substring(fileName + 1, fileNameLength) //截
      console.log(fileFormat)
      if (fileFormat == 'pdf' || fileFormat == 'PDF') {
        const a = document.createElement('a') // 创建a标签
        a.setAttribute('download', item.content) // download属性
        a.setAttribute('href', item) // href链接
        return a.click()
      } else {
        window.open(item)
      }
      // window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item)
    },

    handleSearch() {
      this.currentPage = 1
      this.getCarouselList()
    },
    // 获取详情
    async checkOut(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/EducationTraining/videoDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.checkDetail = res.datas
      console.log('会议详情', this.checkDetail)
      this.checkOutDia = true
    },
    // *获取列表
    async getCarouselList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('company_name', this.searchInfo.company_name)
      param.append('filename', this.searchInfo.filename)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/EducationTraining/videoList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      console.log('会议列表', this.tableData)
      this.totalCount = parseInt(res.datas.count)
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    // 富文本
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange({ editor, html, text }) {
      //编辑器文本发生变化
      //this.content可以实时获取到当前编辑器内的文本内容
      // console.log(this.content);
    },
    //清除
    handleClearSubmit() {
      this.addFormData.name = ''
      this.addFormData.url = ''
      this.addFormData.sort = ''
    },
    checkOutDiaClose() {
      this.checkOutDia = false
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getCarouselList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCarouselList()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    // this.myHeaders.Authorization = this.token
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getCarouselList()
  },
  computed: {
    isDisable() {
      if (this.ids.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    max-height: 70vh;
    overflow: auto;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
/deep/ .quill-editor {
  padding: 0;
}
// 图片上传
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .avatar-uploader-margin {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 120px;
}
video,
video:hover,
video:active,
video:visited,
video:link,
video:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  -webkit-tap-highlight-color: transparent;

  outline: none;

  background: none;

  text-decoration: none;
}
.modifyBox {
  margin-top: 40px;
  margin-left: 10px !important;
}
.modifyHeight /deep/ .el-dialog__body {
  max-height: 50vh;
  overflow: auto;
  padding-bottom: 50px;
  // text-align: center;
}
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type='number'] {
  height: 1000px;
  -moz-appearance: textfield !important;
}
.tableRow {
  width: 100%;
}
.positionBox {
  .positionItem:last-child {
    border-bottom: 1px solid #e2e2e2;
  }
  .positionItem {
    border: 1px solid #e2e2e2;
    border-bottom: none;
    p {
      cursor: pointer;
      border-bottom: 1px solid #e2e2e2;
      width: 100%;
      margin: 0;
      background-color: #f2f2f2;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .container {
      padding: 10px;
    }
  }
}
/deep/ .el-tag {
  margin-right: 10px;
}
.disNone {
  display: none;
}
/deep/ .linkClick {
  cursor: pointer;
}
.linkClick:hover {
  color: #409eff;
}
.linkBox {
  width: 90%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
  color: #333;
  // padding-left: 15px;
  padding-left: 5px;
  height: 30px;
  line-height: 30px;
  // border: 1px solid #e4e7ed;
  color: blue;
  margin-top: 5px;
}
</style>
