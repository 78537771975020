<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>巡检班次任务</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>企业名称</span>
        <el-input v-model="companyName" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table border :data="tableData" stripe style="width: 100%">
        <el-table-column align="center" prop="id" label="编号" width="100"></el-table-column>
        <el-table-column align="center" prop="company_name" label="企业名称" width="200">
          <template slot-scope="scope">
            <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="inspection_name" label="班次名称" width="100">
          <template slot-scope="scope">
            <span
              :title="scope.row.inspection_name"
              class="overflowDoit"
            >{{scope.row.inspection_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="type" label="巡检类型" width="100">
          <template slot-scope="scope">
            <span :title="scope.row.type" class="overflowDoit">{{scope.row.type}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="count" label="次数" width="100">
          <template slot-scope="scope">
            <span :title="scope.row.count" class="overflowDoit">{{scope.row.count}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="danger_str" label="风险点名称" width="300">
          <template slot-scope="scope">
            <span :title="scope.row.danger_str" class="overflowDoit">{{scope.row.danger_str}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="user_str" label="指派人">
          <template slot-scope="scope">
            <!-- <div v-html="scope.row.user_str"></div> -->
            <span :title="scope.row.user_str" class="overflowDoit">{{scope.row.user_str}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      dangerName: '1231231232',
      formData: {
        name: '',
      },
      options: [
        {
          value: 1,
          label: '全部',
        },
        {
          value: 2,
          label: '低风险',
        },
        {
          value: 3,
          label: '一般风险',
        },
        {
          value: 4,
          label: '较大风险',
        },
        {
          value: 5,
          label: '重大风险',
        },
      ],
      value: '',
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 20,
      tableData: [],
    }
  },
  methods: {
    handleSearch() {
      this.getNumList()
    },
    async getNumList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('company_name', this.companyName)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/safety_inspection/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getNumList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getNumList()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.getNumList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }
    .selectCol {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    /deep/ .el-input__icon {
      display: none !important;
    }
    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}
/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  .el-button:nth-child(1) {
    margin-left: 10px;
  }
  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }
  .el-button:nth-child(even) {
    width: 50%;
  }
  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
.tableRow {
  padding: 0 10px;
  width: 100%;
}
/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;
  td {
    padding: 5px 0;
  }
  td,
  th.is-leaf {
    border-bottom: 0;
  }
  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}
.el-table::before {
  display: none;
}
// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }
  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    color: #000;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
</style>