<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>学习视频</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>文件名称</span>
        <el-input v-model="searFileName" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
      <el-button @click="handleadd" class="checkOutBtn">新增</el-button>
      <!-- <el-upload
        class="upload-demo"
        action="https://jsonplaceholder.typicode.com/posts/"
        :beforeUpload="beforeAvatarUpload"
        multiple
        :on-exceed="handleExceed"
        :file-list="fileList"
      >
        <el-button
          style="background-color:#89c62e; margin:0 10px;"
          class="checkOutBtn importantD"
        >发布文件</el-button>
      </el-upload>-->
      <el-button
        @click="handleDeleteSome"
        :disabled="isDisable"
        class="checkOutBtn"
        style="background-color:#fb0103"
      >批量删除</el-button>
    </el-row>

    <el-row class="tableRowModify">
      <el-table
        :data="tableData"
        stripe
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
        <el-table-column align="center" prop="filename" label="文件名字">
          <template slot-scope="scope">
            <span :title="scope.row.filename" class="overflowDoit">{{scope.row.filename}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="file_url" label="文件地址" width="350">
          <template slot-scope="scope">
            <span
              :title="scope.row.file_url"
              @click="handleLinkClick(scope.row.file_url)"
              class="overflowDoit linkClick"
            >{{scope.row.file_url}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="add_time" label="添加时间" width="120">
          <template slot-scope="scope">
            <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="content" label="内容">
          <template slot-scope="scope">
            <span :title="scope.row.content" class="overflowDoit">{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" class="tableBtn">编辑</el-button>
            <el-button
              @click="handleRemove(scope.row)"
              class="tableBtn"
              style="background-color:#fb0103"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 新增 -->
    <el-dialog
      :title="`添加会议记录`"
      class="riskDia"
      :visible.sync="dialogadd"
      width="40%"
      :before-close="dialogaddClose"
      @close="dialogaddClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="addFormData" label-width="90px">
          <el-form-item label="文件名称" prop="desc">
            <el-input style="width:80%" v-model="addFormData.filename"></el-input>
          </el-form-item>
          <el-form-item label="内容简介" prop="desc">
            <el-input style="width:80%" v-model="addFormData.content"></el-input>
          </el-form-item>
          <el-upload
            style="margin:20px 0 20px 10px"
            ref="addUpLoad"
            class="upload-demo"
            action="/api/Homepage/uploadImg"
            :before-upload="beforeAvatarUpload"
            :show-file-list="false"
          >
            <el-button size="small" type="primary" style="margin-left:0" class="footerBtn">点击上传</el-button>
            <div
              slot="tip"
              v-if="fileName"
              @mouseover="handleOver"
              @mouseleave="handleLeave"
              class="el-upload__tip addCloseI"
            >
              {{this.fileName}}
              <i
                v-if="seen"
                class="el-icon-circle-close iconClose"
                @click="handleAddClear"
              ></i>
            </div>
          </el-upload>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="addFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="handleClearSubmit" class="checkOutBtn footerBtn">清空</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 新增 -->

    <!-- 编辑 -->
    <el-dialog
      :title="`编辑会议记录`"
      class="riskDia"
      :visible.sync="dialogedit"
      width="60%"
      :before-close="dialogeditClose"
      @close="dialogeditClosed"
    >
      <el-row class="tableRowModify editUpdataBox">
        <el-form ref="form" :model="editFormData" label-width="50px">
          <el-form-item label="标题" prop="desc">
            <el-input style="width:50%" v-model="editFormData.filename"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="desc">
            <el-input style="width:50%" v-model="editFormData.content"></el-input>
          </el-form-item>
          <el-upload
            style="margin:20px 0 20px 10px"
            ref="editUpLoad"
            class="upload-demo"
            action="/api/Homepage/uploadImg"
            :before-upload="editbeforeAvatarUpload"
            :show-file-list="false"
          >
            <el-button size="small" style="margin-left:0" type="primary" class="footerBtn">点击上传</el-button>
            <div
              slot="tip"
              v-if="fileNameEdit"
              @mouseover="handleOver"
              @mouseleave="handleLeave"
              class="el-upload__tip addCloseI"
            >
              {{this.fileNameEdit}}
              <i
                v-if="seen"
                class="el-icon-circle-close iconClose"
                @click="handleeditClear"
              ></i>
            </div>
          </el-upload>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="editSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="handleClearSubmit1" class="checkOutBtn footerBtn">清空</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 编辑 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      searFileName: '',
      seen: false,
      fileName: '',
      fileNameEdit: '',
      editId: '',
      addFormData: {
        filename: '',
        file_url: '',
        content: '',
      },
      editFormData: {},
      ids: [],
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 新增
      dialogadd: false,
      // 编辑
      dialogedit: false,
      dangerName: '',
      formData: {
        name: '',
      },
      currentPage: 1,
      totalCount: 0,
      pageSize: 20,
      tableData: [],
      currentPage: 1,
      totalCount: 0,
      tableData: [],
    }
  },

  methods: {
    // 点击链接
    handleLinkClick(item) {
      let fileName = item.lastIndexOf('.') //取到文件名开始到最后一个点的长度
      let fileNameLength = item.length //取到文件名长度
      let fileFormat = item.substring(fileName + 1, fileNameLength) //截
      console.log(fileFormat)
      if (fileFormat == 'pdf' || fileFormat == 'PDF') {
        const a = document.createElement('a') // 创建a标签
        a.setAttribute('download', item.content) // download属性
        a.setAttribute('href', item) // href链接
        return a.click()
      }
      if (
        fileFormat == 'png' ||
        fileFormat == 'PNG' ||
        fileFormat == 'bmp' ||
        fileFormat == 'BMP' ||
        fileFormat == 'jpeg' ||
        fileFormat == 'JPEG' ||
        fileFormat == 'jpg' ||
        fileFormat == 'JPG' ||
        fileFormat == 'gif' ||
        fileFormat == 'GIF' ||
        fileFormat == 'mp4' ||
        fileFormat == 'MP4'
      ) {
        return window.open(item)
      }
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + item)
    },
    handleSearch() {
      this.currentPage = 1
      this.getDangerList()
    },
    handleLeave() {
      this.seen = false
    },
    handleOver() {
      this.seen = true
    },
    // *自定义上传
    // 新增
    async beforeAvatarUpload(file) {
      if (this.addFormData.url) {
        return this.$message.warning('只能上传一个文件')
      }
      console.log(file)
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('上传成功')
      this.addFormData.file_url = res.datas.img_url
      this.fileName = file.name
      return false
    },
    handleAddClear() {
      this.fileName = ''
      this.addFormData.file_url = ''
    },
    // 编辑
    async editbeforeAvatarUpload(file) {
      if (this.editFormData.url) {
        return this.$message.warning('只能上传一个文件')
      }
      console.log(file)
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('上传成功')
      this.editFormData.file_url = res.datas.img_url
      this.fileNameEdit = file.name
      return false
    },
    // 点击x
    handleeditClear() {
      this.editFormData.file_url = ''
      this.fileNameEdit = ''
    },
    // *自定义上传
    // *编辑
    async handleEdit(row) {
      this.editId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/fileDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editFormData = res.datas
      let arr = res.datas.file_url.split('/')
      this.fileNameEdit = arr[arr.length - 1]
      this.dialogedit = true
    },
    // *编辑提交
    async editSubmit() {
      console.log('this.editFormData.file_url', this.editFormData.file_url)
      if (this.editFormData.filename.trim().length == 0)
        return this.$message.error('请填写文件名称')
      if (!this.editFormData.file_url) return this.$message.error('请上传文件')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      param.append('filename', this.editFormData.filename)
      param.append('file_url', this.editFormData.file_url)
      param.append('content', this.editFormData.content)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/fileEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)
      this.dialogedit = false
      this.getDangerList()
    },
    // *新增
    handleadd() {
      this.dialogadd = true
    },
    // *新增提交
    async addFormSubmit() {
      if (this.addFormData.filename.trim().length == 0)
        return this.$message.error('请填写文件名称')
      if (!this.addFormData.file_url) return this.$message.error('请上传文件')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('filename', this.addFormData.filename)
      param.append('file_url', this.addFormData.file_url)
      param.append('content', this.addFormData.content)
      param.append('type', 3)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/fileAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)
      this.dialogadd = false
      this.getDangerList()
    },
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach((item) => {
        arr.push(item.id)
      })
      this.ids = [...arr]
    },
    // *批量删除
    async handleDeleteSome() {
      const isDelete = await this.$confirm('是否批量删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消批量删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', this.ids)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/GovExam/fileDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getDangerList()
      }
    },
    // *删除
    async handleRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/GovExam/fileDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getDangerList()
      }
    },
    // 获取列表
    async getDangerList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('type', 3)
      param.append('filename', this.searFileName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/fileList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log('会议记录列表', res)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    // 富文本
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange({ editor, html, text }) {
      //编辑器文本发生变化
      //this.content可以实时获取到当前编辑器内的文本内容
      // console.log(this.content);
    },
    //清除
    handleClearSubmit() {
      this.addFormData.title = ''
      this.addFormData.describe = ''
      this.addFormData.content = ''
    },
    //清除
    handleClearSubmit1() {
      this.editFormData = {}
    },
    //提交
    async Position_submit() {
      //alert('区域网格名称'+this.addForm.areaName+'经度'+this.addForm.x+'维度'+this.addForm.y+'备注'+this.addForm.remark)
      // areaName: '',
      // x: '',
      // y: '',
      // remark: '',
      console.log(this.addForm.areaName)
      console.log(this.addForm.remark)
      console.log(this.addForm.x)
      console.log(this.addForm.y)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('grid_name', this.addForm.areaName)
      param.append('remarks', this.addForm.remark)
      param.append('lng', this.addForm.x)
      param.append('lat', this.addForm.y)
      if (this.addForm.areaName == '') {
        this.$message('请添加区域网格名称')
        return false
      }
      if (this.addForm.x == '') {
        this.$message('请添加经度')
        return false
      }
      if (this.addForm.y == '') {
        this.$message('请添加纬度')
        return false
      }
      this.dialogDetailClose()
      const { data: res } = await this.$axios({
        method: 'post',
        // url: 'http://anquan.sxpz.vip/GovApi/RegionalGrid/add',
        url: '/api/RegionalGrid/add',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      //this.$message.error(res.datas.msg)
      console.log(res)
      this.getDangerList()
    },
    // 新增
    dialogaddClose() {
      this.dialogadd = false
    },
    dialogaddClosed() {
      this.addFormData.title = ''
      this.addFormData.describe = ''
      this.addFormData.content = ''
    },
    // 编辑
    dialogeditClose() {
      this.dialogedit = false
    },
    dialogeditClosed() {
      this.editId = ''
      this.editFormData = {}
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getDangerList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDangerList()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getDangerList()
  },
  computed: {
    isDisable() {
      if (this.ids.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 20px;
      display: flex;
      align-items: center;
      span {
        // width: 76px;
      }
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    max-height: 70vh;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    max-height: 60vh;
    padding: 10px 20px 30px;
    overflow: auto;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  // position: absolute;
  // bottom: 20px;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.tableRowModify {
  width: 100%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
/deep/ .quill-editor {
  padding: 0;
}
.avatar-uploader-display {
  display: none;
}

// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
/deep/ .linkClick {
  cursor: pointer;
}
.linkClick:hover {
  color: #409eff;
}
</style>
