<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>通知公告</span>
    </el-row>
    <el-row class="selectRow">
      <!-- <div class="selectCol" style="width:240px">
        <span>区域网格名称</span>
        <el-input v-model="companyName" placeholder="请输入内容"></el-input>
      </div>-->
      <el-button @click="handleadd" class="checkOutBtn">发布通知公告</el-button>
      <!-- <el-upload
        class="upload-demo"
        action="https://jsonplaceholder.typicode.com/posts/"
        :beforeUpload="beforeAvatarUpload"
        multiple
        :on-exceed="handleExceed"
        :file-list="fileList"
      >
        <el-button
          style="background-color:#89c62e; margin:0 10px;"
          class="checkOutBtn importantD"
        >发布文件</el-button>
      </el-upload>-->
      <el-button
        @click="handleDeleteSome"
        :disabled="isDisable"
        class="checkOutBtn"
        style="background-color:#fb0103"
      >批量删除</el-button>
    </el-row>

    <el-row class="tableRowModify">
      <el-table
        :data="tableData"
        stripe
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
        <el-table-column align="center" prop="title" label="标题">
          <template slot-scope="scope">
            <span :title="scope.row.title" class="overflowDoit">{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="describe" label="简介" width="150">
          <template slot-scope="scope">
            <span :title="scope.row.describe" class="overflowDoit">{{scope.row.describe}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="addtime" label="添加时间" width="200">
          <template slot-scope="scope">
            <span :title="scope.row.addtime" class="overflowDoit">{{scope.row.addtime}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" class="tableBtn">编辑</el-button>
            <el-button
              @click="handleRemove(scope.row)"
              class="tableBtn"
              style="background-color:#fb0103"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 新增 -->
    <el-dialog
      :title="`添加通知公告`"
      class="riskDia"
      :visible.sync="dialogadd"
      width="60%"
      :before-close="dialogaddClose"
      @close="dialogaddClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="addFormData" label-width="50px">
          <el-form-item label="标题" prop="desc">
            <el-input style="width:50%" v-model="addFormData.title"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="desc">
            <el-input style="width:50%" v-model="addFormData.describe"></el-input>
          </el-form-item>
          <el-form-item label="内容" class="needBottom" prop="desc">
            <quill-editor
              ref="QuillEditor"
              class="ql-editor"
              id="detailsquill"
              v-model="addFormData.content"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @change="onEditorChange($event)"
            ></quill-editor>
            <el-upload
              class="avatar-uploader avatar-uploader-display"
              action="#"
              ref="addUpLoad"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="addFormData.url" :src="addFormData.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="addFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="handleClearSubmit" class="checkOutBtn footerBtn">清空</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 新增 -->

    <!-- 编辑 -->
    <el-dialog
      :title="`编辑通知公告`"
      class="riskDia"
      :visible.sync="dialogedit"
      width="60%"
      :before-close="dialogeditClose"
      @close="dialogeditClosed"
    >
      <el-row class="tableRowModify editUpdataBox">
        <el-form ref="form" :model="editFormData" label-width="50px">
          <el-form-item label="标题" prop="desc">
            <el-input style="width:50%" v-model="editFormData.title"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="desc">
            <el-input style="width:50%" v-model="editFormData.describe"></el-input>
          </el-form-item>
          <el-form-item label="内容" class="needBottom" prop="desc">
            <quill-editor
              ref="editQuillEditor"
              class="ql-editor"
              id="editdetailsquill"
              v-model="editFormData.content"
              :options="editorOptionedit"
            ></quill-editor>
            <el-upload
              class="avatar-uploader avatar-uploader-display"
              action="#"
              ref="addUpLoad"
              :show-file-list="false"
              :before-upload="beforeAvatarUploadEdit"
            >
              <img v-if="editFormData.url" :src="editFormData.url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="editSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="handleClearSubmit1" class="checkOutBtn footerBtn">清空</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 编辑 -->
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import * as Quill from 'quill'
//quill编辑器的字体
var fonts = [
  'SimSun',
  'SimHei',
  'Microsoft-YaHei',
  'KaiTi',
  'FangSong',
  'Arial',
  'Times-New-Roman',
  'sans-serif',
]
var Font = Quill.import('formats/font')
Font.whitelist = fonts //将字体加入到白名单
Quill.register(Font, true)
//图片缩放
import ImageResize from 'quill-image-resize-module'
import companyListVue from '../manage/companyList.vue'
Quill.register('modules/imageResize', ImageResize)
// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['clean'], // remove formatting button
]
// const titleConfig = [
//   { Choice: '.ql-bold', title: '加粗' },
//   { Choice: '.ql-italic', title: '斜体' },
//   { Choice: '.ql-underline', title: '下划线' },
//   { Choice: '.ql-header', title: '段落格式' },
//   { Choice: '.ql-strike', title: '删除线' },
//   { Choice: '.ql-blockquote', title: '块引用' },
//   { Choice: '.ql-code', title: '插入代码' },
//   { Choice: '.ql-code-block', title: '插入代码段' },
//   { Choice: '.ql-font', title: '字体' },
//   { Choice: '.ql-size', title: '字体大小' },
//   { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
//   { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
//   { Choice: '.ql-direction', title: '文本方向' },
//   { Choice: '.ql-header[value="1"]', title: 'h1' },
//   { Choice: '.ql-header[value="2"]', title: 'h2' },
//   { Choice: '.ql-align', title: '对齐方式' },
//   { Choice: '.ql-color', title: '字体颜色' },
//   { Choice: '.ql-background', title: '背景颜色' },
//   { Choice: '.ql-image', title: '图像' },
//   { Choice: '.ql-link', title: '添加链接' },
//   { Choice: '.ql-formula', title: '插入公式' },
//   { Choice: '.ql-clean', title: '清除字体格式' },
//   { Choice: '.ql-script[value="sub"]', title: '下标' },
//   { Choice: '.ql-script[value="super"]', title: '上标' },
//   { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
//   { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
//   { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
//   { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
//   { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
//   { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
//   { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
//   { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
//   { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
//   { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
//   { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
//   { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
//   { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
//   { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
//   { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
//   {
//     Choice: '.ql-align .ql-picker-item[data-value="center"]',
//     title: '居中对齐',
//   },
//   {
//     Choice: '.ql-align .ql-picker-item[data-value="right"]',
//     title: '居右对齐',
//   },
//   {
//     Choice: '.ql-align .ql-picker-item[data-value="justify"]',
//     title: '两端对齐',
//   },
// ]
export default {
  data() {
    return {
      editId: '',
      addFormData: {
        title: '',
        describe: '',
        content: '',
      },
      editFormData: {},
      ids: [],
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 新增
      dialogadd: false,
      // 编辑
      dialogedit: false,
      dangerName: '',
      formData: {
        name: '',
      },
      currentPage: 1,
      totalCount: 0,
      pageSize: 20,
      tableData: [],
      currentPage: 1,
      totalCount: 0,
      tableData: [],
      // todo编辑
      editorOptionedit: {
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  // alert('自定义图片')
                  // 调用element图片上传
                  document
                    .querySelector('.editUpdataBox .avatar-uploader-icon')
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
          imageResize: {}, // 配置图片可缩放大小
        },
      },
      // todo新增
      editorOption: {
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  // alert('自定义图片')
                  // 调用element图片上传
                  document
                    .querySelector('.addUpdataBox .avatar-uploader-icon')
                    .click()
                } else {
                  this.quill.format('image', false)
                }
              },
            },
          },
          imageResize: {}, // 配置图片可缩放大小
        },
      },
      // editorOption: {
      //   //字体
      //   modules: {
      //     toolbar: [
      //       ['bold', 'italic', 'underline', 'strike'],
      //       ['blockquote', 'code-block'],

      //       [{ header: 1 }, { header: 2 }],
      //       [{ list: 'ordered' }, { list: 'bullet' }],
      //       [{ script: 'sub' }, { script: 'super' }],
      //       [{ indent: '-1' }, { indent: '+1' }],
      //       [{ direction: 'rtl' }],

      //       [{ size: ['small', false, 'large', 'huge'] }],
      //       [{ header: [1, 2, 3, 4, 5, 6, false] }],

      //       [{ color: [] }, { background: [] }],
      //       [{ font: fonts }], //把上面定义的字体数组放进来

      //       [{ align: [] }],

      //       ['clean'],
      //       ['image', 'video'],
      //     ],
      //     imageResize: {}, // 配置图片可缩放大小
      //   },
      //   theme: 'snow',
      // },
    }
  },

  methods: {
    // 自定义图片上传
    async beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        // data: param,
        data: param,
      })
      // return console.log('res', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)

      let quill = this.$refs.QuillEditor.quill
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res.datas.img_url)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败')
      }
      return new Promise((resolve, reject) => {
        this.$refs['addUpLoad'].validate((valid) => {
          if (valid) {
            return resolve(true)
          } else {
            return reject(false)
          }
        })
      })
    },
    //  todo编辑
    // 自定义图片上传
    async beforeAvatarUploadEdit(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        // data: param,
        data: param,
      })
      // return console.log('res', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)

      let quill = this.$refs.editQuillEditor.quill
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res.datas.img_url)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        // 提示信息，需引入Message
        this.$message.error('图片插入失败')
      }
      console.log('asdasdasdas')
      return new Promise((resolve, reject) => {
        this.$refs['addUpLoad'].validate((valid) => {
          if (valid) {
            return resolve(true)
          } else {
            return reject(false)
          }
        })
      })
    },
    // 自定义图片上传
    // *编辑
    async handleEdit(row) {
      this.editId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editFormData = res.datas

      this.dialogedit = true
    },
    // *编辑提交
    async editSubmit() {
      if (this.editFormData.content.trim().length == 0)
        return this.$message.error('请填写内容')
      if (this.editFormData.title.trim().length == 0)
        return this.$message.error('请填写标题')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      param.append('content', this.editFormData.content)
      param.append('title', this.editFormData.title)
      param.append('describe', this.editFormData.describe)
      param.append('type', 1)
      param.append('style', 1)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)
      this.dialogedit = false
      this.getDangerList()
    },
    // *新增
    handleadd() {
      this.dialogadd = true
    },
    // *新增提交
    async addFormSubmit() {
      if (this.addFormData.content.trim().length == 0)
        return this.$message.error('请填写内容')
      if (this.addFormData.title.trim().length == 0)
        return this.$message.error('请填写标题')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('content', this.addFormData.content)
      param.append('title', this.addFormData.title)
      param.append('describe', this.addFormData.describe)
      param.append('type', 1)
      param.append('style', 1)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)
      this.dialogadd = false
      this.getDangerList()
    },
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach((item) => {
        arr.push(item.id)
      })
      this.ids = [...arr]
    },
    // *批量删除
    async handleDeleteSome() {
      const isDelete = await this.$confirm('是否批量删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消批量删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', this.ids)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Homepage/newsDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getDangerList()
      }
    },
    // *删除
    async handleRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Homepage/newsDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getDangerList()
      }
    },
    // 获取列表
    async getDangerList() {
      // let param = new URLSearchParams()
      // param.append('access_token', this.token)
      // param.append('page', this.currentPage)
      // param.append('length', this.pageSize)
      // param.append('style', 1)
      // const { data: res } = await this.$axios({
      //   method: 'post',
      //   url: '/api/Homepage/newsList',
      //   data: param,
      // })
      // if (res.code != 200) return this.$message.error(res.datas.msg)
      // this.tableData = res.datas.list
      // this.totalCount = parseInt(res.datas.count)

      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('style', 1)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/newsList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log('课件', res)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    // 富文本
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange({ editor, html, text }) {
      //编辑器文本发生变化
      //this.content可以实时获取到当前编辑器内的文本内容
      // console.log(this.content);
    },
    //清除
    handleClearSubmit() {
      this.addFormData.title = ''
      this.addFormData.describe = ''
      this.addFormData.content = ''
    },
    //清除
    handleClearSubmit1() {
      this.editFormData = {}
    },
    //提交
    async Position_submit() {
      //alert('区域网格名称'+this.addForm.areaName+'经度'+this.addForm.x+'维度'+this.addForm.y+'备注'+this.addForm.remark)
      // areaName: '',
      // x: '',
      // y: '',
      // remark: '',
      console.log(this.addForm.areaName)
      console.log(this.addForm.remark)
      console.log(this.addForm.x)
      console.log(this.addForm.y)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('grid_name', this.addForm.areaName)
      param.append('remarks', this.addForm.remark)
      param.append('lng', this.addForm.x)
      param.append('lat', this.addForm.y)
      if (this.addForm.areaName == '') {
        this.$message('请添加区域网格名称')
        return false
      }
      if (this.addForm.x == '') {
        this.$message('请添加经度')
        return false
      }
      if (this.addForm.y == '') {
        this.$message('请添加纬度')
        return false
      }
      this.dialogDetailClose()
      const { data: res } = await this.$axios({
        method: 'post',
        // url: 'http://anquan.sxpz.vip/GovApi/RegionalGrid/add',
        url: '/api/RegionalGrid/add',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      //this.$message.error(res.datas.msg)
      console.log(res)
      this.getDangerList()
    },
    // 新增
    dialogaddClose() {
      this.dialogadd = false
    },
    dialogaddClosed() {
      this.addFormData.title = ''
      this.addFormData.describe = ''
      this.addFormData.content = ''
    },
    // 编辑
    dialogeditClose() {
      this.dialogedit = false
    },
    dialogeditClosed() {
      this.editId = ''
      this.editFormData = {}
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getDangerList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDangerList()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getDangerList()
  },
  computed: {
    isDisable() {
      if (this.ids.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    max-height: 70vh;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    max-height: 60vh;
    padding: 10px 20px 30px;
    overflow: auto;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  // position: absolute;
  // bottom: 20px;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.tableRowModify {
  width: 100%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
/deep/ .quill-editor {
  padding: 0;
}
.avatar-uploader-display {
  display: none;
}

// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
</style>
