<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>考试管理</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol">
        <span>公司名称</span>
        <el-input v-model="searchInfo.company_name" placeholder="请输入内容"></el-input>
      </div>
      <div class="selectCol">
        <span>考试名称</span>
        <el-input v-model="searchInfo.filename" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
      <el-button @click="handleAddTest" class="checkOutBtn">新增</el-button>
    </el-row>

    <el-row class="tableRow">
      <el-table
        :data="tableData"
        stripe
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
        <el-table-column align="center" prop="filename" label="考试名称">
          <template slot-scope="scope">
            <span :title="scope.row.filename" class="overflowDoit">{{scope.row.filename}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="300" label="考试内容">
          <template slot-scope="scope">
            <span :title="scope.row.content" class="overflowDoit">{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="add_time" label="添加时间">
          <template slot-scope="scope">
            <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button @click="handleTestEdit(scope.row)" class="tableBtn">编辑</el-button>
            <el-button
              @click="handleTestRemove(scope.row)"
              class="tableBtn"
              style="background-color:#fb0103"
            >删除</el-button>
            <el-button @click="handleGetList1(scope.row)" class="tableBtn">习题列表</el-button>
            <el-button @click="handleGetList2(scope.row)" class="tableBtn">下发记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 20, 30]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 考试新增 -->
    <el-dialog
      :title="`添加考试`"
      class="riskDia"
      :visible.sync="testAdd"
      width="40%"
      :before-close="testAddClose"
      @close="testAddClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="addFormTest" label-width="90px">
          <el-form-item label="考试名称" prop="desc">
            <el-input style="width:80%" v-model="addFormTest.filename"></el-input>
          </el-form-item>
          <el-form-item label="考试内容" prop="desc">
            <el-input style="width:80%" v-model="addFormTest.content"></el-input>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="addFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="testAdd=false" class="checkOutBtn footerBtn">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 考试新增 -->
    <!-- 考试编辑 -->
    <el-dialog
      :title="`编辑考试`"
      class="riskDia"
      :visible.sync="testEdit"
      width="40%"
      :before-close="testEditClose"
      @close="testEditClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="editFormTest" label-width="90px">
          <el-form-item label="考试名称" prop="desc">
            <el-input style="width:80%" v-model="editFormTest.filename"></el-input>
          </el-form-item>
          <el-form-item label="考试内容" prop="desc">
            <el-input style="width:80%" v-model="editFormTest.content"></el-input>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="editFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="testEdit=false" class="checkOutBtn footerBtn">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 考试编辑 -->

    <!-- 习题列表 -->
    <el-dialog
      :title="`习题列表`"
      class="riskDia"
      :visible.sync="dialogadd"
      width="80%"
      :before-close="dialogaddClose"
      @close="dialogaddClosed"
    >
      <el-row class="tableRowModify">
        <el-row>
          <el-button @click="handleAddExample" class="checkOutTableBtn">新增</el-button>
        </el-row>
        <el-table border :data="tableData1" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="title" label="试题内容">
            <template slot-scope="scope">
              <span :title="scope.row.title" class="overflowDoit">{{scope.row.title}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="answer" label="试题答案">
            <template slot-scope="scope">
              <span :title="scope.row.answer" class="overflowDoit">{{scope.row.answer}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="add_time" label="添加时间">
            <template slot-scope="scope">
              <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="option1" label="选项1" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.option1" class="overflowDoit">{{scope.row.option1}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="option2" label="选项2" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.option2" class="overflowDoit">{{scope.row.option2}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="option3" label="选项3" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.option3" class="overflowDoit">{{scope.row.option3}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="option4" label="选项4" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.option4" class="overflowDoit">{{scope.row.option4}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="option5" label="选项5" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.option5" class="overflowDoit">{{scope.row.option5}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="option6" label="选项6" width="80">
            <template slot-scope="scope">
              <span :title="scope.row.option6" class="overflowDoit">{{scope.row.option6}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <div style="display:flex;">
                <el-button
                  style="margin:0 auto!important;"
                  @click="checkList1(scope.row)"
                  class="tableBtnRisk"
                >编辑</el-button>
                <el-button
                  @click="handleExampleRemove(scope.row)"
                  style="background-color:#fb0103;margin:0 auto!important;"
                  class="tableBtnRisk"
                >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData1.length>0"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="currentPage1"
          :page-sizes="[3, 5, 10, 15,20]"
          :page-size="pageSize1"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount1"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 习题列表 -->
    <!-- 习题列表新增 -->
    <el-dialog
      :title="`添加习题`"
      class="riskDia"
      :visible.sync="exampleAdd"
      width="40%"
      :before-close="exampleAddClose"
      @close="exampleAddClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="addFormExample" label-width="123px">
          <el-form-item label="试题内容">
            <el-input style="width:80%" v-model="addFormExample.title"></el-input>
          </el-form-item>
          <el-form-item label="试题答案">
            <el-input style="width:80%" v-model="addFormExample.answer"></el-input>
          </el-form-item>
          <el-form-item label="题目类型">
            <el-radio @change="handleRadioChange" v-model="addFormExample.type" label="0">单选</el-radio>
            <el-radio @change="handleRadioChange" v-model="addFormExample.type" label="1">多选</el-radio>
            <el-radio @change="handleRadioChange" v-model="addFormExample.type" label="2">简答</el-radio>
          </el-form-item>
          <el-form-item label="选项1" v-if="showOptions">
            <el-input style="width:80%" v-model="addFormExample.option1"></el-input>
          </el-form-item>
          <el-form-item label="选项2" v-if="showOptions">
            <el-input style="width:80%" v-model="addFormExample.option2"></el-input>
          </el-form-item>
          <el-form-item label="选项3" v-if="showOptions">
            <el-input style="width:80%" v-model="addFormExample.option3"></el-input>
          </el-form-item>
          <el-form-item label="选项4" v-if="showOptions">
            <el-input style="width:80%" v-model="addFormExample.option4"></el-input>
          </el-form-item>
          <el-form-item label="选项5" v-if="showOptions">
            <el-input style="width:80%" v-model="addFormExample.option5"></el-input>
          </el-form-item>
          <el-form-item label="选项6" v-if="showOptions">
            <el-input style="width:80%" v-model="addFormExample.option6"></el-input>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="addFormExampleSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="exampleAdd=false" class="checkOutBtn footerBtn">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 习题列表新增 -->
    <!-- 习题详情-编辑 -->
    <el-dialog
      :title="`习题修改`"
      class="riskDia modifyHeight"
      :visible.sync="list1Detail"
      width="40%"
      :before-close="list1DetailClose"
      @close="list1DetailClosed"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="detailData1" label-width="123px">
          <el-form-item label="试题内容">
            <el-input style="width:80%" v-model="detailData1.title"></el-input>
          </el-form-item>
          <el-form-item label="试题答案">
            <el-input style="width:80%" v-model="detailData1.answer"></el-input>
          </el-form-item>
          <el-form-item label="题目类型">
            <el-radio @change="handleRadioChange" v-model="detailData1.type" label="0">单选</el-radio>
            <el-radio @change="handleRadioChange" v-model="detailData1.type" label="1">多选</el-radio>
            <el-radio @change="handleRadioChange" v-model="detailData1.type" label="2">简答</el-radio>
          </el-form-item>
          <el-form-item label="选项1" v-if="showOptions">
            <el-input style="width:80%" v-model="detailData1.option1"></el-input>
          </el-form-item>
          <el-form-item label="选项2" v-if="showOptions">
            <el-input style="width:80%" v-model="detailData1.option2"></el-input>
          </el-form-item>
          <el-form-item label="选项3" v-if="showOptions">
            <el-input style="width:80%" v-model="detailData1.option3"></el-input>
          </el-form-item>
          <el-form-item label="选项4" v-if="showOptions">
            <el-input style="width:80%" v-model="detailData1.option4"></el-input>
          </el-form-item>
          <el-form-item label="选项5" v-if="showOptions">
            <el-input style="width:80%" v-model="detailData1.option5"></el-input>
          </el-form-item>
          <el-form-item label="选项6" v-if="showOptions">
            <el-input style="width:80%" v-model="detailData1.option6"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="footerBox">
        <el-button @click="editFormExampleSubmit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="list1Detail=false" class="checkOutBtn footerBtn">取消</el-button>
      </el-row>
    </el-dialog>
    <!-- 习题详情-编辑 -->

    <!-- 下发记录 -->
    <el-dialog
      :title="`下发记录`"
      class="riskDia"
      :visible.sync="downDia"
      width="80%"
      :before-close="downDiaClose"
      @close="downDiaClosed"
    >
      <el-row>
        <el-button @click="handleAddSend" class="checkOutTableBtn">新增</el-button>
      </el-row>
      <el-row class="tableRowModify">
        <el-table border :data="tableData2" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="exam_count" label="习题数量">
            <template slot-scope="scope">
              <span :title="scope.row.exam_count" class="overflowDoit">{{scope.row.exam_count}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="start_time" label="考试开始时间">
            <template slot-scope="scope">
              <span :title="scope.row.start_time" class="overflowDoit">{{scope.row.start_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="end_time" label="考试结束时间">
            <template slot-scope="scope">
              <span :title="scope.row.end_time" class="overflowDoit">{{scope.row.end_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="add_time" label="下发时间">
            <template slot-scope="scope">
              <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="320">
            <template slot-scope="scope">
              <div style="display:flex">
                <el-button
                  style="margin:0 auto!important;"
                  @click="checkList2(scope.row)"
                  class="tableBtnRisk"
                >编辑</el-button>
                <el-button
                  @click="handleSendRemove(scope.row)"
                  style="background-color:#fb0103;margin:0 auto!important;"
                  class="tableBtnRisk"
                >删除</el-button>
                <el-button
                  style="margin:0 auto!important;"
                  @click="testList(scope.row)"
                  class="tableBtnRisk"
                >考试记录</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData2.length>0"
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[3, 5, 10, 15,20]"
          :page-size="pageSize2"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount2"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 下发记录 -->
    <!-- 下发记录-新增 -->
    <el-dialog
      :title="`添加下发`"
      class="riskDia"
      :visible.sync="sendAdd"
      width="40%"
      :before-close="sendAddClose"
      @close="sendAddClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="addFormSned" label-width="123px">
          <el-form-item label="考试开始时间">
            <el-date-picker
              style="width:80%"
              v-model="addFormSned.start_time"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="考试结束时间">
            <el-date-picker
              style="width:80%"
              v-model="addFormSned.end_time"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="习题数量">
            <el-input style="width:80%" type="number" v-model="addFormSned.exam_count"></el-input>
          </el-form-item>
          <el-form-item label="下发企业">
            <el-checkbox-group v-model="addFormSned.user">
              <el-checkbox
                v-for="item in bandList"
                :key="item.id"
                :label="item.id"
              >{{item.nickname}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="addFormSendSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="sendAdd=false" class="checkOutBtn footerBtn">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 下发记录-新增 -->
    <!-- 下发记录-编辑 -->
    <el-dialog
      :title="`下发编辑`"
      class="riskDia modifyHeight"
      :visible.sync="list2Detail"
      width="50%"
      :before-close="list2DetailClose"
      @close="list2DetailClosed"
    >
      <el-row class="tableRowModify addUpdataBox">
        <el-form ref="form" :model="detailData2" label-width="123px">
          <el-form-item label="考试开始时间">
            <el-date-picker
              style="width:80%"
              v-model="detailData2.start_time"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="考试结束时间">
            <el-date-picker
              style="width:80%"
              v-model="detailData2.end_time"
              type="date"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="习题数量">
            <el-input style="width:80%" type="number" v-model="detailData2.exam_count"></el-input>
          </el-form-item>
          <el-form-item label="下发企业">
            <el-checkbox-group v-model="bindEditList">
              <el-checkbox
                v-for="item in bandList"
                :key="item.id"
                :label="item.id"
              >{{item.nickname}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <el-row class="footerBox">
          <el-button @click="editFormSendSubmit" class="checkOutBtn footerBtn">提交</el-button>
          <el-button @click="list2Detail=false" class="checkOutBtn footerBtn">取消</el-button>
        </el-row>
      </el-row>
    </el-dialog>
    <!-- 下发记录-编辑 -->
    <!-- 下发记录--考试记录 -->
    <el-dialog
      :title="`考试记录`"
      class="riskDia"
      :visible.sync="testDia"
      width="80%"
      :before-close="testClose"
      @close="testClosed"
    >
      <el-row class="tableRowModify">
        <el-table border :data="tableData3" stripe style="width: 100%">
          <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
          <el-table-column align="center" prop="exam_count" label="考试人">
            <template slot-scope="scope">
              <span
                :title="scope.row.exam_count"
                class="overflowDoit"
              >{{scope.row.nickname+'-'+scope.row.username}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="count" label="考试得分">
            <template slot-scope="scope">
              <span :title="scope.row.count" class="overflowDoit">{{scope.row.count}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="add_time" label="考试结束时间">
            <template slot-scope="scope">
              <span :title="scope.row.add_time" class="overflowDoit">{{scope.row.add_time}}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                style="margin:0 auto!important;"
                @click="testCheckOut(scope.row)"
                class="tableBtnRisk"
              >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="tableData3.length>0"
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page="currentPage3"
          :page-sizes="[3, 5, 10, 15,20]"
          :page-size="pageSize3"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount3"
        ></el-pagination>
      </el-row>
    </el-dialog>
    <!-- 下发记录--考试记录 -->

    <!-- 考试详情 -->
    <el-dialog
      :title="`考试详情`"
      class="riskDia modifyHeight"
      :visible.sync="testDetails"
      width="50%"
      :before-close="testDetailsClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="testData" label-width="100px">
          <el-form-item label="试卷详情">
            <div class="testDetailsBox">
              <div class="testItem" v-for="item,index in testData.content">
                <div class="testTitle">
                  {{(index+1)+'.'}}
                  <span v-if="item.questionType==0" style="color:blue">{{`(单选)`}}</span>
                  <span v-if="item.questionType==1" style="color:orange">{{`(多选)`}}</span>
                  <span v-if="item.questionType==2" style="color:skyblue">{{`(简答)`}}</span>
                  {{item.fldName}}
                </div>
                <div class="testAnswer" style="color:green">{{"正确答案:"+item.right}}</div>
                <div class="testAnswer" style="color:red">{{"考生答案:"+item.fldAnswer}}</div>
                <div
                  class="testOptions"
                  v-if="item.questionType!=2"
                  v-for="items in item.QuestionOptionList"
                >{{items.fldOptionIndex+'.'+items.fldOptionText}}</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="考试结束时间">
            <el-input style="width:90%" disabled v-model="testData.add_time"></el-input>
          </el-form-item>
          <el-form-item label="考试得分">
            <el-input style="width:90%" disabled v-model="testData.count"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 考试详情 -->
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data() {
    return {
      bandList: [],
      fileList: [],
      dialogaddV: false,
      videoUrl: '',
      // 图片上传
      imageUrl: '',
      editId: '',
      ids: [],
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 新增
      dialogadd: false,
      // 编辑
      dialogedit: false,
      dangerName: '',
      formData: {
        name: '',
      },
      currentPage: 1,
      totalCount: 0,
      pageSize: 20,
      tableData: [],
      currentPage1: 1,
      totalCount1: 0,
      pageSize1: 10,
      tableData1: [],
      currentPage2: 1,
      totalCount2: 0,
      pageSize2: 10,
      tableData2: [],
      // 考试记录列表
      currentPage3: 1,
      totalCount3: 0,
      pageSize3: 10,
      tableData3: [],
      // 考试记录列表
      checkOutId: '',
      searchInfo: {
        company_name: '',
        filename: '',
      },
      list1Detail: false,
      list2Detail: false,
      testDia: false,
      testDetails: false,
      downDia: false,
      testData: {},
      detailData1: {},
      detailData2: {},
      isreload: true,
      testId: '',
      editTestId: '',
      showOptions: true,
      // 考试新增
      testAdd: false,
      addFormTest: {
        filename: '',
        content: '',
      },
      // 考试编辑
      testEdit: false,
      editFormTest: {},
      // 习题列表新增
      exampleAdd: false,
      //    点击编辑的习题id(!!)
      editClickExampleId: '',
      addFormExample: {
        title: '',
        answer: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        option5: '',
        option6: '',
        type: '0',
      },
      // 下发新增
      sendAdd: false,
      addFormSned: {
        exam_count: '',
        start_time: '',
        end_time: '',
        user: [],
      },
      // 下发编辑
      editSendId: '',
      bindEditList: [],
    }
  },

  methods: {
    // 提交-下发记录-编辑
    async editFormSendSubmit() {
      if (!this.detailData2.start_time)
        return this.$message.error('请选择考试开始时间')
      if (!this.detailData2.end_time)
        return this.$message.error('请选择考试结束时间')
      if (this.detailData2.exam_count.trim().length == 0)
        return this.$message.error('请填写习题数量')
      if (this.bindEditList.length == 0)
        return this.$message.error('请绑定下发企业')
      // return console.log('this.addFormSned.user', this.addFormSned.user)
      let newStr = this.bindEditList.join(',')
      // return console.log(newStr)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editSendId)
      param.append('exam_count', this.detailData2.exam_count)
      param.append('start_time', this.detailData2.start_time)
      param.append('end_time', this.detailData2.end_time)
      param.append('user', newStr)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/issueEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('修改成功')
      this.list2Detail = false
      this.getList2()
    },
    // 删除-下发记录
    async handleSendRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/GovExam/issueDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getList2()
      }
    },
    // 新增-下发记录-提交
    async addFormSendSubmit() {
      if (!this.addFormSned.start_time)
        return this.$message.error('请选择考试开始时间')
      if (!this.addFormSned.end_time)
        return this.$message.error('请选择考试结束时间')
      if (this.addFormSned.exam_count.trim().length == 0)
        return this.$message.error('请填写习题数量')
      if (this.addFormSned.user.length == 0)
        return this.$message.error('请绑定下发企业')
      // return console.log('this.addFormSned.user', this.addFormSned.user)
      let newStr = this.addFormSned.user.join(',')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('exam_id', this.checkOutId)
      param.append('exam_count', this.addFormSned.exam_count)
      param.append('start_time', this.addFormSned.start_time)
      param.append('end_time', this.addFormSned.end_time)
      param.append('user', newStr)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/issueAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('添加成功')
      this.sendAdd = false
      this.getList2()
    },
    // 新增-下发记录
    handleAddSend() {
      this.sendAdd = true
    },
    // 删除-习题列表
    async handleExampleRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/GovExam/exercisesDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getList1()
      }
    },
    // 编辑-习题列表-提交
    async editFormExampleSubmit() {
      if (this.detailData1.title.trim().length == 0)
        return this.$message.error('考试题目不能为空')
      if (this.detailData1.answer.trim().length == 0)
        return this.$message.error('考试答案不能为空')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editClickExampleId)
      param.append('title', this.detailData1.title)
      param.append('answer', this.detailData1.answer)
      param.append('option1', this.detailData1.option1)
      param.append('option2', this.detailData1.option2)
      param.append('option3', this.detailData1.option3)
      param.append('option4', this.detailData1.option4)
      param.append('option5', this.detailData1.option5)
      param.append('option6', this.detailData1.option6)
      param.append('type', this.detailData1.type)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/exercisesEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('修改成功')
      this.list1Detail = false
      this.getList1()
    },
    // 新增-习题列表
    handleAddExample() {
      this.exampleAdd = true
    },
    // 新增-习题列表-提交
    async addFormExampleSubmit() {
      if (this.addFormExample.title.trim().length == 0)
        return this.$message.error('考试题目不能为空')
      if (this.addFormExample.answer.trim().length == 0)
        return this.$message.error('考试答案不能为空')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('exam_id', this.checkOutId)
      param.append('title', this.addFormExample.title)
      param.append('answer', this.addFormExample.answer)
      param.append('option1', this.addFormExample.option1)
      param.append('option2', this.addFormExample.option2)
      param.append('option3', this.addFormExample.option3)
      param.append('option4', this.addFormExample.option4)
      param.append('option5', this.addFormExample.option5)
      param.append('option6', this.addFormExample.option6)
      param.append('type', this.addFormExample.type)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/exercisesAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('添加成功')
      this.exampleAdd = false
      this.getList1()
    },
    // 删除-考试
    async handleTestRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/GovExam/examDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getCarouselList()
      }
    },
    // 编辑-考试-获取数据
    async getTestDetail() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editTestId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/examDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editFormTest = res.datas
      console.log('考试详情', this.editFormTest)
    },
    // 编辑-考试
    handleTestEdit(row) {
      this.editTestId = row.id
      this.getTestDetail()
      this.testEdit = true
    },
    // 编辑-考试-提交
    async editFormSubmit() {
      if (this.editFormTest.filename.trim().length == 0)
        return this.$message.error('考试名称不能为空')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editTestId)
      param.append('filename', this.editFormTest.filename)
      param.append('content', this.editFormTest.content)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/examEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('修改成功')
      this.testEdit = false
      this.getCarouselList()
    },
    // 新增-考试
    handleAddTest() {
      this.testAdd = true
    },
    // 新增-考试-提交
    async addFormSubmit() {
      if (this.addFormTest.filename.trim().length == 0)
        return this.$message.error('考试名称不能为空')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('filename', this.addFormTest.filename)
      param.append('content', this.addFormTest.content)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/examAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('添加成功')
      this.testAdd = false
      this.getCarouselList()
    },
    itemClick(val) {
      this.isreload = false
      this.isreload = true
      let index = this.detailData2.position.findIndex((element) => {
        return element.id == val.id
      })
      console.log('asdindex', index)
      this.detailData2.position[index].isShow = !this.detailData2.position[
        index
      ].isShow
      console.log(
        'this.detailData2.position[0].isShow',
        this.detailData2.position[0].isShow
      )
      console.log(
        'this.detailData2.position[1].isShow',
        this.detailData2.position[1].isShow
      )
      // item.isShow = !item.isShow
    },
    handleSearch() {
      this.currentPage = 1
      this.getCarouselList()
    },
    // *获取习题列表
    async getList1() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage1)
      param.append('length', this.pageSize1)
      param.append('exam_id', this.checkOutId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/exercisesList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData1 = res.datas.list
      console.log('习题列表', this.tableData1)
      this.totalCount1 = parseInt(res.datas.count)
    },
    // 查看习题详情
    async checkList1(row) {
      this.editClickExampleId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/exercisesDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.detailData1 = res.datas
      if (this.detailData1.type == '2') {
        this.showOptions = false
      } else {
        this.showOptions = true
      }
      this.list1Detail = true
    },
    // *获取下发记录
    async getList2() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage2)
      param.append('length', this.pageSize2)
      param.append('exam_id', this.checkOutId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/issueList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData2 = res.datas.list
      console.log('下发记录', this.tableData1)
      this.totalCount2 = parseInt(res.datas.count)
    },
    // 下发记录-考试记录
    async getTestList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage3)
      param.append('length', this.pageSize3)
      param.append('merge_id', this.testId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/recordList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData3 = res.datas.list
      console.log('考试记录列表', this.tableData3)
      this.totalCount3 = parseInt(res.datas.count)
    },
    async testList(row) {
      this.testId = row.id
      this.getTestList()
      this.testDia = true
    },
    // 下发记录-考试记录-查看详情
    async testCheckOut(row) {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/recordDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.testData = res.datas
      console.log('考试详情', this.tsetDetails)
      this.testDetails = true
    },
    // 查看下发详情
    async checkList2(row) {
      this.editSendId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/issueDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.detailData2 = res.datas
      // return console.log('res', res)
      // this.detailData2.position[0].list[0].is_select = 1
      res.datas.list.forEach((item) => {
        if (item.is_select == 1) {
          this.bindEditList.push(item.id)
        }
      })
      console.log('下发详情', this.detailData2)
      console.log('下发详情-绑定企业', this.bindEditList)
      this.list2Detail = true
    },
    handleRadioChange(item) {
      if (item == '2') {
        this.showOptions = false
        this.addFormExample.option1 = ''
        this.addFormExample.option2 = ''
        this.addFormExample.option3 = ''
        this.addFormExample.option4 = ''
        this.addFormExample.option5 = ''
        this.addFormExample.option6 = ''
        this.detailData1.option1 = ''
        this.detailData1.option2 = ''
        this.detailData1.option3 = ''
        this.detailData1.option4 = ''
        this.detailData1.option5 = ''
        this.detailData1.option6 = ''
      } else {
        this.showOptions = true
      }
    },
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach((item) => {
        arr.push(item.id)
      })
      this.ids = [...arr]
    },
    handleGetList1(row) {
      this.checkOutId = row.id
      this.getList1()
      this.dialogadd = true
    },
    handleGetList2(row) {
      this.checkOutId = row.id
      this.getList2()
      this.downDia = true
    },
    // *获取列表
    async getCarouselList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('company_name', this.searchInfo.company_name)
      param.append('filename', this.searchInfo.filename)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/examList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      console.log('链接列表', this.tableData)
      this.totalCount = parseInt(res.datas.count)
    },
    // 获取绑定企业列表
    async getBandList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/GovExam/companyList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.bandList = res.datas
      console.log('绑定企业列表', this.bandList)
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    // 富文本
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange({ editor, html, text }) {
      //编辑器文本发生变化
      //this.content可以实时获取到当前编辑器内的文本内容
      // console.log(this.content);
    },
    //清除
    handleClearSubmit() {
      this.addFormData.name = ''
      this.addFormData.url = ''
      this.addFormData.sort = ''
    },
    //清除
    handleClearSubmit1() {
      this.editFormData = {}
    },
    handleClearSubmit2() {
      this.videoUrl = ''
    },
    // 新增
    dialogaddClose() {
      this.dialogadd = false
    },
    dialogaddClosed() {
      this.checkOutId = ''
    },
    downDiaClose() {
      this.downDia = false
    },
    downDiaClosed() {
      this.checkOutId = ''
    },
    list1DetailClose() {
      this.list1Detail = false
    },
    list1DetailClosed() {
      this.editClickExampleId = ''
      this.showOptions = true
    },
    list2DetailClose() {
      this.list2Detail = false
    },
    list2DetailClosed() {
      this.detailData2 = {}
      this.bindEditList = []
      this.editSendId = ''
    },
    testClose() {
      this.testDia = false
    },
    testClosed() {
      this.testId = ''
    },
    testDetailsClose() {
      this.testDetails = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.getCarouselList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCarouselList()
    },
    handleSizeChange1(val) {
      this.currentPage1 = 1
      this.pageSize1 = val
      this.getList1()
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val
      this.getList1()
    },
    handleSizeChange2(val) {
      this.currentPage2 = 1
      this.pageSize2 = val
      this.getList2()
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val
      this.getList2()
    },
    handleSizeChange3(val) {
      this.currentPage3 = 1
      this.pageSize3 = val
      this.getTestList()
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val
      this.getTestList()
    },
    dialogaddVClose() {
      this.dialogaddV = false
    },
    dialogaddvClosed() {
      this.videoUrl = ''
    },
    testAddClose() {
      this.testAdd = false
    },
    testAddClosed() {
      this.addFormTest.filename = ''
      this.addFormTest.content = ''
    },
    testEditClose() {
      this.testEdit = false
    },
    testEditClosed() {
      this.editFormTest = {}
      this.editTestId = ''
    },
    exampleAddClose() {
      this.exampleAdd = false
    },
    exampleAddClosed() {
      this.addFormExample.title = ''
      this.addFormExample.answer = ''
      this.addFormExample.option1 = ''
      this.addFormExample.option2 = ''
      this.addFormExample.option3 = ''
      this.addFormExample.option4 = ''
      this.addFormExample.option5 = ''
      this.addFormExample.option6 = ''
      this.addFormExample.type = '0'
      this.showOptions = true
    },
    sendAddClose() {
      this.sendAdd = false
    },
    sendAddClosed() {
      this.addFormSned.exam_count = ''
      this.addFormSned.start_time = ''
      this.addFormSned.end_time = ''
      this.addFormSned.user = []
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    // this.myHeaders.Authorization = this.token
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getBandList()
    this.getCarouselList()
  },
  computed: {
    isDisable() {
      if (this.ids.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    max-height: 70vh;
    overflow: auto;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  padding-top: 10px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
/deep/ .quill-editor {
  padding: 0;
}
// 图片上传
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .avatar-uploader-margin {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 120px;
}
video,
video:hover,
video:active,
video:visited,
video:link,
video:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  -webkit-tap-highlight-color: transparent;

  outline: none;

  background: none;

  text-decoration: none;
}
.modifyBox {
  margin-top: 40px;
  margin-left: 10px !important;
}
.modifyHeight /deep/ .el-dialog__body {
  max-height: 50vh;
  overflow: auto;
  padding-bottom: 50px;
  // text-align: center;
}
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type='number'] {
  height: 1000px;
  -moz-appearance: textfield !important;
}
.tableRow {
  width: 100%;
}
.positionBox {
  .positionItem:last-child {
    border-bottom: 1px solid #e2e2e2;
  }
  .positionItem {
    border: 1px solid #e2e2e2;
    border-bottom: none;
    p {
      cursor: pointer;
      border-bottom: 1px solid #e2e2e2;
      width: 100%;
      margin: 0;
      background-color: #f2f2f2;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .container {
      padding: 10px;
    }
  }
}
/deep/ .el-tag {
  margin-right: 10px;
}
.disNone {
  display: none;
}
.testDetailsBox {
  width: 90%;
  border: 1px solid #e2e2e2;
  padding: 15px;
  .testAnswer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .testOptions {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.checkOutTableBtn {
  width: 80px !important;
  font-size: 12px;
  padding: 5px 20px !important;
  background-color: #447ed9;
  color: #fff;
}
</style>
