<template>
  <div class="map">
    <div>{{a}}</div>
    <el-amap class="amap-box" vid="amapDemo" :zoom="zoom" :center="center" :plugin="plugin">
      <!-- 标记点 -->
      <el-amap-marker :position="center" vid="marker"></el-amap-marker>
      <!-- 圆 -->
      <!-- <el-amap-circle vid="circle" :center="center" :radius="radius" fill-opacity="0.2" strokeColor="#38f"
        strokeOpacity="0.8" strokeWeight="1" fillColor="#38f"></el-amap-circle> -->
      <el-amap-polygon v-for="(polygon,key) in polygons" :path="polygon.path" :key="polygons.value" :events="polygon.events"></el-amap-polygon>
    </el-amap>
  </div>
</template>

<script>
  import {
    AMapManager
  } from 'vue-amap';
  import {
    lazyAMapApiLoaderInstance
  } from 'vue-amap';
  import {
    addressSetMapCenter
  } from "./location.js"
  import {
    amapSetMarker
  } from "./marker.js"
  import {
    getLonLan
  } from "./common.js"
  export default {
    data() {
      let self = this;
      return {
        map: '',
        token: '',
        a: this.zl,
        name: '',
        zooms: 18,
        zoom: 18,
        centers: [113.72105, 34.772123],
        center: [113.72105, 34.772123], //圆心位置
        radius: 200, //圆半径，单位:米
        loaded: false,
        polygons: [{
          draggable: true,
          path: [
            [113.667919, 34.764208],
            [113.667935, 34.763648],
            [113.668622, 34.763648],
            [113.668718, 34.764208],
          ], //左上 左下  右下  右上
          events: {
            click: () => {
              alert('click polygon');
              //console.log(amapManager.getComponent(0));
              // console.log(this.$refs.map.$$getCenter())
              // console.log(this.$refs.polygon_0[0].$$getPath())
            }
          }
        }],
        plugin: [{
          pName: 'Geolocation',
          showMarker: false,
          events: {
            // init(o) {

            //   //定位成功 自动将marker和circle移到定位点
            //   o.getCurrentPosition((status, result) => {
            //     console.log(result);
            //     if (result && result.position) {

            //       self.center = [result.position.lng, result.position.lat]
            //       self.loaded = true;
            //     } else {
            //       console.log(`定位失败`)
            //     }
            //   });
            //   console.log(o);
            // }
          }
        }]

      };
    },
    props: ['zl'],
    computed: {
      normalizedSize: function() {
        return this.ctype.trim().toLowerCase()
      }
    },
    methods: {



      async getPosition() {
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('id', this.zl)
        //param.append('company_name', this.companyName)
        //param.append('danger_name', this.danName)
        const {
          data: res
        } = await this.$axios({
          method: 'post',
          url: '/api/RegionalGrid/detail',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.data.datas.msg)
        console.log(res.datas)
        this.center[0] = parseFloat(res.datas.lng)
        this.center[1] = parseFloat(res.datas.lat)
        this.centers[0] = parseFloat(res.datas.lng)
        this.centers[1] = parseFloat(res.datas.lat)
        console.log(this.center)
        console.log(this.center)
      }
    },
    created: function() {
      this.token = localStorage.getItem('btlh_token') || ''
      this.userName = localStorage.getItem('btlh_nickname') || ''
      this.getPosition()
    },
    updated() {
    },
    mounted() {




      // lazyAMapApiLoaderInstance.load().then(() => {
      //   // your code ...
      //   console.log('地图加载成功')

      //     this.map = new AMap.Map('amapDemo', {
      //       center: this.centers,
      //       zoom: this.zooms,
      //     });

      // });
    }
  };
</script>

<style scoped>
  .map {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
  }

  .amap-wrapper {
    width: 500px;
    height: 500px;
  }
</style>
