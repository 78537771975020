<template>
  <div class="select-map">
    <!-- {{a}} -->
    <p id="leval">
      区域网格名称：
      <span id="map-zoom">{{areaName}}</span>
    </p>
    <!-- <div class="box">
      <div class="box_children">
        <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button>
        <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :before-close="handleClose">
          <span>这是一段信息</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div> -->
    <div class="bt_father">
      <div class="list_position">
        <button class="btn_list" v-for="item in company" @click="listClick(item.lng,item.lat,item.id)" style="">
          {{item.nickname}}</button>
        <!-- <button class="btn_list">a</button>
        <button class="btn_list">a</button>
        <button class="btn_list">a</button>
        <button class="btn_list">a</button>
        <button class="btn_list">a</button>
        <button class="btn_list">a</button>
        <button class="btn_list">a</button>
        <button class="btn_list">a</button> -->
      </div>

    </div>
    <div id="selectAll-maps" style=""></div>

    <el-dialog title="企业信息" :visible.sync="dialogVisible" :modal="false" top="30vh" width="30%">
      <div class="dialog_border" >
        <el-row :gutter="50">
          <el-col :span="24">
            企业名称：{{com_name}}
          </el-col>
          <el-col :span="24">
            企业地址：{{com_add}}
          </el-col>
          <el-col :span="24">
            <span>主要负责人：{{com_charge_name}}</span> <span class="el_span">电话：{{com_charge_mobile}}</span>
          </el-col>
          <el-col :span="24">
            <span>安全负责人：{{com_safe_name}}</span> <span class="el_span">电话：{{com_safe_mobile}}</span>
          </el-col>
        </el-row>
        <!-- <span slot="footer" class="dialog-footer" >
        <el-button type="primary" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
      </div>
    </el-dialog>
  </div>
</template>
<!-- <script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.15&key=ed874656da9395f37265c6814a45e7e7"></script> -->

<script>
  import {
    AMapManager
  } from 'vue-amap';
  import {
    lazyAMapApiLoaderInstance
  } from 'vue-amap';
  import {
    addressSetMapCenter
  } from "./location.js"
  import {
    amapSetMarker
  } from "./marker.js"
  import {
    getLonLan
  } from "./common.js"
  export default {
    data() {
      return {
        id: '',
        a: this.zl,
        center: [113.42946, 34.703061],
        zooms: [2, 20],
        content: '',
        dialogVisible: false,
        map: {}, //保存地图对象
        mouseTool: {}, // 鼠标多边形绘制工具
        polyType: {
          borderColor: '',
          fillOpacity: 0.5,
          fillColor: '',
        },
        areaName: '',
        polygonList: [],
        savePolygon: [],
        points: '',
        pointsPush: '', //获取的矢量图形坐标点
        company: [],
        uBounds: [], //接收的 未转换
        uBounds1: [], //转换后
        imgPosition: [], //四色图坐标
        com_name: '',
        com_add: '',
        com_charge_name: '', //主要负责人
        com_charge_mobile: '', //主要手机号
        com_safe_name: '',
        com_safe_mobile: '',
      }
    },
    props: ['zl'],
    computed: {
      normalizedSize: function() {
        return this.ctype.trim().toLowerCase()
      },
    },
    created: function() {
      this.token = localStorage.getItem('btlh_token') || ''
      this.userName = localStorage.getItem('btlh_nickname') || ''
      this.g()
      this.getPosition()
      this.getIsMine()
    },
    methods: {
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },

      listClick(a, b, c) { // 经度 、纬度 、id
        console.log(a)
        console.log(b)
        console.log(c)
        this.center[0] = a
        this.center[1] = b
        console.log(this.center)
        this.company_marker(c)
        this.createMap()
      },

      async getPosition() {
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('id', this.zl)
        //param.append('company_name', this.companyName)
        //param.append('danger_name', this.danName)
        const {
          data: res
        } = await this.$axios({
          method: 'post',
          url: '/api/RegionalGrid/detail',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.data.datas.msg)
        console.log(res)
        console.log(res.datas)
        this.areaName = res.datas.grid_name
        this.points = JSON.parse(res.datas.points)

        console.log(this.points)
        if (this.points != null) {
          const a = this.points.split(",")
          var arr = a.map(Number);
          console.log(arr)
          const x = []
          for (var index = 0; index < arr.length; index += 2) {
            x.push([arr[index], arr[index + 1]]);
          }
          this.pointsPush = x;
          console.log(x);

        }
        this.company = res.datas.list //公司坐标
		console.log('公司坐标')
        console.log(this.company)
        this.center[0] = parseFloat(res.datas.lng)
        this.center[1] = parseFloat(res.datas.lat)
        this.createMap()
        //this.centers[0] = parseFloat(res.datas.lng)
        //this.centers[1] = parseFloat(res.datas.lat)
        console.log(this.center)
        //console.log(this.center)
      },
      async getIsMine() {
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('id', this.zl)
        const {
          data: res
        } = await this.$axios({
          method: 'post',
          url: '/RegionalGrid/company_total',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.data.datas.msg)
        console.log('getIsMine')
        console.log(res)
      },

      // 限制地图显示范围
      setLimit() {
        var boundss = this.map.getBounds()
        this.map.setLimitBounds(boundss)
      },
      // 绘制多边形
      // drawPolygon() {
      //   let that = this
      //   this.mouseTool = new AMap.MouseTool(this.map)

      //   this.mouseTool.polygon({
      //     strokeColor: this.polyType.borderColor,
      //     fillColor: this.polyType.fillColor,
      //     fillOpacity: parseFloat(this.polyType.fillOpacity / 100),
      //     //同Polygon的Option设置
      //   })
      //   //监听draw事件可获取画好的覆盖物
      //   this.polygonList = []
      //   this.mouseTool.on('draw', function(e) {
      //     that.polygonList.push(e.obj)
      //     console.log('绘制的多边形', that.polygonList)
      //   })
      // },
      // 清空地图上绘制的多边形
      // clearPolygon() {
      //   this.map.remove(this.polygonList)
      //   this.polygonList = []
      // },
      // cleanAll() {
      //   this.map.remove(this.savePolygon)
      // },

      // 结束绘制多边形
      // closeDrawPolygon() {
      //   this.mouseTool.close()
      //   let savePolygon = []
      //   for (let i = 0; i < this.polygonList.length; i++) {
      //     console.log(this.polygonList[i].getOptions())
      //     let op = this.polygonList[i].getOptions()
      //     let routePath = []
      //     op.path.forEach((item) => {
      //       routePath.push([item.lng, item.lat])
      //     })
      //     let savearr = {
      //       path: routePath,
      //       strokeColor: op.strokeColor,
      //       strokeOpacity: op.strokeOpacity,
      //       fillOpacity: op.fillOpacity,
      //       fillColor: op.fillColor,
      //       zIndex: op.zIndex,
      //     }
      //     this.savePolygon.push(savearr)
      //   }
      //   let save = JSON.stringify(this.savePolygon)
      //   console.log('savePolygon', save)
      //   sessionStorage.setItem('savePolygon', save)
      // },
      // 创建地图

      createMap() {
        let that = this

        this.$nextTick(() => {

          //地图加载
          that.map = new AMap.Map('selectAll-maps', {
            scrollWheel: true,
            zooms: this.zooms,
            zoom: 12,
            center: this.center,
            layers: [new AMap.TileLayer],
            mapStyle: 'amap://styles/normal'
            // layers: [
            //             new AMap.TileLayer(),
            //             imageLayer
            //         ]

          })
         

          var path = this.pointsPush
          console.log("zuobiao " + path)
          // if(this.pointsPush='') path=[0]



          var polygon = new AMap.Polygon({
            path: path,
            strokeColor: "#55aaff",
            strokeWeight: 6,
            strokeOpacity: 0.5,
            fillOpacity: 0.4,
            fillColor: '#55ff00',
            zIndex: 10,
          })

          that.map.add(polygon)




          var lnglats = this.company
          // [
          //   [113.700092,34.790484],
          //   [113.727386,34.762073],
          //   [113.70095,34.743385],
          //   [113.736827,34.737954]
          // ];
          var markers = [];

          for (var i = 0; i < lnglats.length; i++) {
            mouseover: (e) => {
              console.log('移入')
            }
            var lnglat = lnglats[i];
            var lng = parseFloat(lnglat.lng)
            var lat = parseFloat(lnglat.lat)
            var content = lnglat.nickname

            // 创建点实例
            var marker = new AMap.Marker({
              map: this.map,
              position: new AMap.LngLat(lng, lat),
              label: {
                offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
                content: content,
              },
              extData: {
                id: i + 1
              }
            });
            marker.id = lnglat.id

            marker.on('click', function(e) {
              that.dialogVisible = true
              console.log(e)
              that.center[0] = e.lnglat.lng
              that.center[1] = e.lnglat.lat
              let id = e.target.id
              console.log(id)

              //that.center= new AMap.LngLat(lnglat[0], lnglat[1])
              // var marker = new AMap.Marker({
              //   map: this.map,
              //   position: new AMap.LngLat(lng, lat),
              //   label: {
              //     offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
              //     content: this.content,
              //   },
              // })
              that.company_marker(id)

              // that.createMap()

              //this.content="<div >天地人面粉</div><img  src='"+require('./16_91_2.png')+"' />"
            })
            markers.push(marker);
          }
          var overlayGroups = new AMap.OverlayGroup(markers);
          this.map.add(overlayGroups);



          // var marker1 = new AMap.Marker({
          //   map: this.map,
          //   position: [113.42946, 34.703061],
          //   label: {
          //     offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
          //     content: this.content,
          //   },
          // })
          // marker1.on('click', function(e) {
          //   // marker1.markOnAMAP({
          //   //     name:'天地人面粉实业有限公司',
          //   //     position:marker1.getPosition()
          //   // })

          //   console.log(e)
          //   that.center = [113.42946, 34.703061]
          //   //alert("天地人面粉实业有限公司")
          //   // that.map=new AMap.Map("selectAll-map",{
          //   //                 zooms: this.zooms,
          //   //                 zoom: 16,
          //   //                 //layers: [new AMap.TileLayer.Satellite()],
          //   //                 center: [113.42946, 34.703061]
          //   //             })
          //   var marker1 = new AMap.Marker({
          //     map: this.map,
          //     position: [113.42946, 34.703061],
          //     label: {
          //       offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
          //       content: this.content,
          //     },
          //   })

          //   that.createMap()
          //   //this.content="<div >天地人面粉</div><img  src='"+require('./16_91_2.png')+"' />"
          // })

          // var marker2 = new AMap.Marker({
          //   map: this.map,
          //   position: [113.62809491534425, 34.752607366196806],
          //   label: {
          //     offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
          //     content: this.content,
          //   },
          // })
          // marker2.on('click', function(e) {
          //   // marker1.markOnAMAP({
          //   //     name:'天地人面粉实业有限公司',
          //   //     position:marker1.getPosition()
          //   // })
          //   console.log(e)
          //   that.center = [113.62809491534425, 34.752607366196806]
          //   //alert("天地人面粉实业有限公司")
          //   // that.map=new AMap.Map("selectAll-map",{
          //   //                 zooms: this.zooms,
          //   //                 zoom: 16,
          //   //                 //layers: [new AMap.TileLayer.Satellite()],
          //   //                 center: [113.42946, 34.703061]
          //   //             })
          //   var marker2 = new AMap.Marker({
          //     map: this.map,
          //     position: [113.62809491534425, 34.752607366196806],
          //     label: {
          //       offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
          //       content: this.content,
          //     },
          //   })

          //   that.createMap()

          //   //this.content="<div >天地人面粉</div><img  src='"+require('./16_91_2.png')+"' />"
          // })
          // 地图上打印当前的鼠标点击点位
          that.map.on('click', function(e) {
            console.log(e)
            var add = [e.lnglat.getLng(), e.lnglat.getLat()]
            console.log(add)
            console.log(e.lnglat.getLng() + ',' + e.lnglat.getLat())
            this.center = add
            console.log(this.center)
          })
          // this.zoomMonitor()
          // 限制地图使用范围
          this.setLimit()
        })
      },
      //获取企业详细信息
      async company_marker(id) {
        var that = this
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('id', id)
        const {
          data: res
        } = await this.$axios({
          method: 'post',
          url: '/api/RegionalGrid/company_detail',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        console.log(res)
        this.com_name = res.datas.nickname
        this.com_add = '企业地址'
        this.com_charge_name = res.datas.charge_name
        this.com_charge_mobile = res.datas.charge_mobile
        this.com_safe_name = res.datas.safe_name
        this.com_safe_mobile = res.datas.safe_mobile
        this.imgPosition = res.datas.file_url
        this.uBounds[0] = res.datas.bounds00
        this.uBounds[1] = res.datas.bounds01
        this.uBounds[2] = res.datas.bounds10
        this.uBounds[3] = res.datas.bounds11
        console.log(this.uBounds)

        //转换成数字

        var Ubounds = this.uBounds.map(Number);

        console.log(Ubounds)


        const u = []
        for (var index = 0; index < Ubounds.length; index += 2) {
          u.push([Ubounds[index], Ubounds[index + 1]]);
        }
        console.log(u)
        this.uBounds1 = u
        //alert(res.datas.file_url)
        var imageLayer = new AMap.ImageLayer({
          url: that.imgPosition,
          bounds: new AMap.Bounds(
            that.uBounds1[0], that.uBounds1[1]
            // [113.649379, 34.837075],
            // [113.757182, 34.888345]
          ),
          zIndex: 1000,
          zooms: [12, 20],

        });
        this.map.add(imageLayer)
      },
      // 用保存的数据重新绘制多边形并添加事件
      resetDraw() {
        let that = this
        let savePolygon = JSON.parse(sessionStorage.getItem('savePolygon'))
        console.log('重绘多边形数据', savePolygon)
        for (let i = 0; i < savePolygon.length; i++) {
          var polygon = new AMap.Polygon(savePolygon[i])
          polygon.on('click', function(e) {
            console.log('点击了' + i + '个内容', e)
            that.dialogVisible = true
          })
          this.map.add(polygon)
        }
      },
      zoomMonitor() { //地图级别监听
        var zoom = this.map.getZoom()
        console.log(zoom)
      },
      g() {
        // this.content="<div >天地人面粉</div><img src='"+require('./16_91_2.png')+"' style='width:100px;height:100px'  class='i'/>"
        this.content =
          `
          <div>天地人面粉</div>
          <img src="${require('./16_91_2.png')}" class="i"/>
        `
      },
    },
    mounted() {
      // this.zoomMonitor()
      // this.getPosition()
      // this.g()
      //this.createMap()
    },
    destroyed() {

      console.log(this.map)
      // this.map.clearMap()
    },
  }
</script>
<style lang="scss" scoped>
  .bt_father {
    position: relative;
  }

  .list_position {
    background-color: white;
    width: 18%;
    height: 62vh;
    z-index: 1000000000;
    position: absolute;
    overflow-y: scroll;

  }

  .btn_list {
    border-radius: 5px;
    outline: 0 none !important;
    blr: expression(this.onFocus=this.blur());
    height: 40px;
    width: 250px;
    background-color: #447ed9;
    ;
    color: white;
    border: solid 1px #FFFFFF;

  }

  .btn_list:hover {
    background-color: #70B9E8;
  }

  .btn_list :active {
    background: #269CE9;

  }

  .select-map {
    width: 100%;
    height: 100%;
    z-index: 100;

    #selectAll-maps {
      width: 100%;
      height: 88%;
    }

    // /deep/.amap-marker-label {
    //   width: 100px;
    //   height: 100px;
    // }

    /deep/ .i {
      width: 100px;
      height: 90px;
    }
  }

  .box {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .box_children {
    left: 50%;
    transform: -50%;
    width: 300px;
    height: 300px;
    top: 20%;
    position: relative;
    z-index: 10000000;
    background-color: #fff;
  }

  .el-row {

    left: 20px;
    font-size: 10px;
    line-height: 30px;
    margin-right: 30px;
    bottom: 1px;
  }

  .el-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .el_span {
    margin-right: 50px;
  }

  .dialog_border{
    padding: 10px;
    box-sizing: border-box;
    margin: 0px -5px;
    box-shadow: 0 0 8px #ddd;

  }

  .el-dialog__body{
    padding: 0 !important;
  }
</style>
