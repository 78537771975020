<template>
  <div class="select-map" >
    <div id="selectAll-map"></div>
    <p id="leval">当前级别：<span id="map-zoom">15</span></p>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      center:[113.42946,34.703061],
      zooms:[11, 20],
      content:'',
      dialogVisible: false,
      map: {}, //保存地图对象
      mouseTool: {}, // 鼠标多边形绘制工具
      polyType: {
        borderColor: "",
        fillOpacity: 0.5,
        fillColor: ""
      },
      polygonList: [],
      savePolygon: []
    };
  },
  methods: {
    // 限制地图显示范围
    setLimit() {
      var bounds = this.map.getBounds();
      this.map.setLimitBounds(bounds);
    },
    // 绘制多边形
    drawPolygon() {
      let that = this;
      this.mouseTool = new AMap.MouseTool(this.map);

      this.mouseTool.polygon({
        strokeColor: this.polyType.borderColor,
        fillColor: this.polyType.fillColor,
        fillOpacity: parseFloat(this.polyType.fillOpacity / 100)
        //同Polygon的Option设置
      });
      //监听draw事件可获取画好的覆盖物
      this.polygonList = [];
      this.mouseTool.on("draw", function(e) {
        that.polygonList.push(e.obj);
        console.log("绘制的多边形", that.polygonList);
      });
    },
    // 清空地图上绘制的多边形
    clearPolygon() {
      this.map.remove(this.polygonList);
      this.polygonList = [];
    },
    cleanAll(){
      this.map.remove(this.savePolygon);
    },

    // 结束绘制多边形
    closeDrawPolygon() {
      this.mouseTool.close();
      let savePolygon = [];
      for (let i = 0; i < this.polygonList.length; i++) {
        console.log(this.polygonList[i].getOptions());
        let op = this.polygonList[i].getOptions();
        let routePath = [];
        op.path.forEach(item => {
          routePath.push([item.lng, item.lat]);
        });
        let savearr = {
          path: routePath,
          strokeColor: op.strokeColor,
          strokeOpacity: op.strokeOpacity,
          fillOpacity: op.fillOpacity,
          fillColor: op.fillColor,
          zIndex: op.zIndex
        };
        this.savePolygon.push(savearr);
      }
      let save = JSON.stringify(this.savePolygon);
      console.log("savePolygon", save);
      sessionStorage.setItem("savePolygon", save);
    },
    // 创建地图
    createMap() {
      let that = this;
      this.$nextTick(() => {
        //地图加载
        that.map = new AMap.Map("selectAll-map", {
          zooms: this.zooms,
          zoom: 15,
          //layers: [new AMap.TileLayer.Satellite()],
          center: this.center
        });

        var marker1 = new AMap.Marker({
          map: this.map,
          position: [113.42946, 34.703061],
          label: {
            offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
            content: this.content,

          },

        })
        marker1.on('click',function(e){
                    // marker1.markOnAMAP({
                    //     name:'天地人面粉实业有限公司',
                    //     position:marker1.getPosition()
                    // })
                    var zoom = that.map.getZoom();
                    console.log(zoom)
                    console.log(e)
                    that.center=[113.42946, 34.703061]
                    //alert("天地人面粉实业有限公司")
                    // that.map=new AMap.Map("selectAll-map",{
                    //                 zooms: this.zooms,
                    //                 zoom: 16,
                    //                 //layers: [new AMap.TileLayer.Satellite()],
                    //                 center: [113.42946, 34.703061]
                    //             })
                                var marker1 = new AMap.Marker({
                                  map: this.map,
                                  position: [113.42946, 34.703061],
                                  label: {
                                    offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
                                    content: this.content,
                                  },

                                })
                                that.createMap();

                    //this.content="<div >天地人面粉</div><img  src='"+require('./16_91_2.png')+"' />"
                })


var marker2 = new AMap.Marker({
          map: this.map,
          position: [113.42146, 34.703061],
          label: {
            offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
            content: this.content,

          },

        })
        marker2.on('click',function(e){
                    // marker1.markOnAMAP({
                    //     name:'天地人面粉实业有限公司',
                    //     position:marker1.getPosition()
                    // })
                    console.log(e)
                    that.center=[113.42146, 34.703061]
                    //alert("天地人面粉实业有限公司")
                    // that.map=new AMap.Map("selectAll-map",{
                    //                 zooms: this.zooms,
                    //                 zoom: 16,
                    //                 //layers: [new AMap.TileLayer.Satellite()],
                    //                 center: [113.42946, 34.703061]
                    //             })
                                var marker2 = new AMap.Marker({
                                  map: this.map,
                                  position: [113.42146, 34.703061],
                                  label: {
                                    offset: new AMap.Pixel(10, 10), //修改label相对于maker的位置
                                    content: this.content,
                                  },

                                })
                                that.createMap();

                    //this.content="<div >天地人面粉</div><img  src='"+require('./16_91_2.png')+"' />"
                })
        // 地图上打印当前的鼠标点击点位
        that.map.on("click", function(e) {
          console.log(e)
          var add=[ e.lnglat.getLng(),e.lnglat.getLat()]
          console.log(add)
          console.log(e.lnglat.getLng() + "," + e.lnglat.getLat());
          this.center=add
          console.log(this.center)
        });
        // 限制地图使用范围
        this.setLimit();
      });
    },
    // 用保存的数据重新绘制多边形并添加事件
    resetDraw() {
      let that = this;
      let savePolygon = JSON.parse(sessionStorage.getItem("savePolygon"));
      console.log("重绘多边形数据", savePolygon);
      for (let i = 0; i < savePolygon.length; i++) {
        var polygon = new AMap.Polygon(savePolygon[i]);
        polygon.on("click", function(e) {
          console.log("点击了" + i + "个内容",e);
          that.dialogVisible = true;
        });
        this.map.add(polygon);
      }
    },
    // g(){
    //     // this.content="<div >天地人面粉</div><img src='"+require('./16_91_2.png')+"' style='width:100px;height:100px'  class='i'/>"
    //     this.content=`
    //       <div>天地人面粉</div>
    //       <img src="${require('./16_91_2.png')}" class="i"/>
    //     `
    // }

  },
  mounted() {
    // this.g()
    this.createMap();

  }
};
</script>
<style lang="scss" scoped>
.select-map {
  width: 100%;
  height: 1000px;
  #selectAll-map {
    width: 100%;
    height: 100%;
  }
  /deep/.amap-marker-label{

 width: 250px;
  height: 300px;
  }
   /deep/ .i{

    width:100%;
    height:95%;
  }
}


</style>
