<template>
  <div class="select-map">
    <!-- {{b}} -->
    <div id="select-map"></div>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->

  </div>
</template>
<script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.15&key=ed874656da9395f37265c6814a45e7e7"></script>
<script>
export default {
  data() {
    return {
      b:this.a,
      gridname:'',
      center: [1,1],
      dialogVisible: false,
      zooms: [11, 20],
      map: {}, //保存地图对象
      mouseTool: {}, // 鼠标多边形绘制工具
      polyType: {
        borderColor: "",
        fillOpacity: 0.5,
        fillColor: ""
      },
      polygonList:'',
      savePolygon: {},
      savepath:[],
      points:[],
    };
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    //this.g()
    this.getPosition()
  },
  props: ['a'],
  computed: {
    normalizedSize: function () {
      return this.ctype.trim().toLowerCase()
    },
  },

  methods: {
    async getPosition() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.b)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.data.datas.msg)
      console.log(res)
      console.log(res.datas)
      this.center[0] = parseFloat(res.datas.lng)
      this.center[1] = parseFloat(res.datas.lat)
      this.gridname= res.datas.grid_name
      this.createMap()
      //this.centers[0] = parseFloat(res.datas.lng)
      //this.centers[1] = parseFloat(res.datas.lat)
      console.log(this.center)
      //console.log(this.center)
    },



    // 限制地图显示范围
    setLimit() {
      var bounds = this.map.getBounds();
      this.map.setLimitBounds(bounds);
    },
    // 绘制多边形
    drawPolygon() {
      this.clearPolygon()
      this.savePolygon=[]
      let that = this;
      this.mouseTool = new AMap.MouseTool(this.map);

      this.mouseTool.polygon({
        strokeColor: this.polyType.borderColor,
        fillColor: this.polyType.fillColor,
        fillOpacity: parseFloat(this.polyType.fillOpacity / 100)
        //同Polygon的Option设置
      });
      //监听draw事件可获取画好的覆盖物
      this.polygonList = [];
      this.mouseTool.on("draw", function(e) {
        console.log(that.polygonList)
        console.log(e)
        that.polygonList.push(e.obj);
        console.log("绘制的多边形", that.polygonList);
      });
    },
    // 清空地图上绘制的多边形
    clearPolygon() {

      this.map.remove(this.polygonList);
      this.polygonList = [];
      //sessionStorage.clear()
      sessionStorage.removeItem('savePolygon');
    },

    // 结束绘制多边形
    closeDrawPolygon() {
      localStorage.removeItem("savePolygon")
      this.mouseTool.close();
      let savePolygon = [];
      for (let i = 0; i < this.polygonList.length; i++) {
        console.log(this.polygonList[i].getOptions());
        let op = this.polygonList[i].getOptions();
        let routePath = [];
        op.path.forEach(item => {
          routePath.push([item.lng, item.lat]);
        });
        this.points=routePath

        console.log(this.points)
        let savearr = {
          path: routePath,
          strokeColor: op.strokeColor,
          strokeOpacity: op.strokeOpacity,
          fillOpacity: op.fillOpacity,
          fillColor: op.fillColor,
          zIndex: op.zIndex
        };
        this.savePolygon.push(savearr);
      }
      let save = JSON.stringify(this.savePolygon);
      console.log( save);

      sessionStorage.setItem("savePolygon", save);
      this.savepath=sessionStorage.getItem('savePolygon');
      console.log(this.savepath)
      this.sendPath()
    },
    //提交path
    async sendPath() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.b)
      param.append('points',this.points)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/with_points',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.data.datas.msg)
      console.log(res)
      console.log(res.datas)
      this.center[0] = parseFloat(res.datas.lng)
      this.center[1] = parseFloat(res.datas.lat)

    },
    // 创建地图
    createMap() {
      let that = this;
      this.$nextTick(() => {
        //地图加载
        that.map = new AMap.Map("select-map", {
          zooms: [11, 20],
          zoom: 12,
          //layers: [new AMap.TileLayer.Satellite()],
          center: this.center
        });
        var marker1 = new AMap.Marker({
          map: this.map,
          position: this.center,
          label: {
            offset: new AMap.Pixel(20, 20), //修改label相对于maker的位置
            content: this.gridname
          }
        })
        // marker1.on('click',function(e){
        //             marker1.markOnAMAP({
        //                 name:'天地人面粉实业有限公司',
        //                 position:marker1.getPosition()
        //             })
        //         })
        // var marker2 = new AMap.Marker({
        //   map: this.map,
        //   position: [113.42546, 34.703061],
        //   label: {
        //     offset: new AMap.Pixel(20, 20), //修改label相对于maker的位置
        //     content: "天地人面粉"
        //   }
        // })
        // marker2.on('click',function(e){
        //             marker.markOnAMAP({
        //                 name:'天地人面粉实业有限公司',
        //                 position:marker.getPosition()
        //             })
        //         })
        // 地图上打印当前的鼠标点击点位
        that.map.on("click", function(e) {
          console.log(e.lnglat.getLng() + "," + e.lnglat.getLat());
        });
        // 限制地图使用范围
        this.setLimit();
      });
    },
    // 用保存的数据重新绘制多边形并添加事件
    resetDraw() {
      let that = this;
      let savePolygon = JSON.parse(sessionStorage.getItem("savePolygon"));
      console.log("重绘多边形数据", savePolygon);
      for (let i = 0; i < savePolygon.length; i++) {
        var polygon = new AMap.Polygon(savePolygon[i]);
        polygon.on("click", function(e) {
          console.log("点击了" + i + "个内容",e);
          that.dialogVisible = true;
        });
        this.map.add(polygon);
      }
    }
  },
  mounted() {
    //this.createMap();
  },
  destroyed() {
    console.log(this.map)
    this.map.clearMap()
  },
};
</script>
<style lang="scss" scoped>
.select-map {
  width: 100%;
  height: 100%;
  #select-map {
    width: 100%;
    height: 100%;
  }
}
</style>
