let marker =null
export function amapSetMarker(lnglat,map){
  if(marker){
    map.remove(marker);
    marker=null;
  }
  // console.log(lnglat)
  // console.log(map)
  marker = new AMap.Marker({
    position:[lnglat.lon,lnglat.lag],

  })
   console.log(marker)
  map.add(marker);
}
