<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>区域网格</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol" style="width:240px">
        <span>区域网格名称</span>
        <el-input v-model="companyName" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
      <el-button @click="addGird" class="checkOutBtn">添加区域网格</el-button>
      <!-- <el-button @click="selectGird" class="checkOutBtn">查看整体网格</el-button> -->
    </el-row>

    <el-row class="tableRowModify">
      <el-table border :data="tableData" stripe style="width: 100%">
        <!-- <el-table-column align="center" prop="id" label="编号"></el-table-column>
        <el-table-column align="center" prop="company_name" label="区域网格名称"></el-table-column>
        <el-table-column align="center" prop="danger_name" label="备注"></el-table-column>
        <el-table-column align="center" label="操作" width="400">-->

        <el-table-column align="center" prop="id" label="编号"></el-table-column>
        <el-table-column align="center" prop="grid_name" label="区域网格名称"></el-table-column>
        <el-table-column align="center" prop="remarks" label="备注"></el-table-column>
        <el-table-column align="center" label="操作" width="400">
          <template slot-scope="scope">
            <el-button @click="handleCheckout(scope.row)" class="tableBtn">查看区域网格</el-button>
            <el-button @click="handleDraw(scope.row)" class="tableBtn">重绘区域网格</el-button>
            <el-button @click="handleBinding(scope.row)" class="tableBtn">绑定企业</el-button>
            <el-button @click="handleRemove(scope.row)" class="tableBtn">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 添加区域网格 -->
    <el-dialog
      :title="`添加区域网格`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="25%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRowModify2">
        <el-form ref="addForm" :model="addForm" label-width="120px">
          <el-form-item label="区域网格名称">
            <el-input style="width:50%" v-model="addForm.areaName"></el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input style="width:50%" v-model="addForm.x"></el-input>
            <el-button @click="handlePosition" class="checkOutBtn footerBtn dingwei" style="left: 70%;">定位</el-button>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input style="width:50%" v-model="addForm.y"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input style="width:50%" v-model="addForm.remark"></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <el-row class="" style="margin-top: 30%; display: flex;">
        <el-button @click="Position_submit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="handleClearSubmit" class="checkOutBtn footerBtn">清空</el-button>
      </el-row>
    </el-dialog>
    <!-- 查看整体网格 -->
    <el-dialog
      :title="`查看整体网格`"
      class="riskDia"
      :visible.sync="selectDetail"
      width="60%"
      :before-close="selectDetailClose"
    >
      <el-row class="tableRowModify">
        <allAreas></allAreas>
      </el-row>
    </el-dialog>

    <!-- 添加区域网格 -->

    <!-- 定位 -->
    <el-dialog
      :title="`定位`"
      class="riskDia"
      :visible.sync="dialogPosition"
      width="60%"
      :before-close="dialogPositionClose"
    >
      <el-row class="tableRowModify">
        <MapPosition @lonlag="position"></MapPosition>
      </el-row>
      <el-row class="footerBox">
        <!-- <el-button @click="handleSearch" class="checkOutBtn footerBtnleft">编辑</el-button>
        <el-button @click="handleSearch" class="checkOutBtn footerBtnleft">提交</el-button>-->
        <!-- <div style="font-size: 25px;line-height: 40px;">点坐标:</div> -->
      </el-row>
      <el-form>
        <el-form-item label>
          <!-- <el-input v-model="lonlag3" style="padding-left: 3%;"></el-input> -->
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 定位 -->

    <!-- 绑定企业 -->
    <el-dialog
      :title="`绑定企业`"
      class="riskDia"
      :visible.sync="dialogbinding"
      width="80%"
      :before-close="dialogbindingClose"
      @close="dialogbindingClosed"
    >
      <!-- <el-row class="tableRowModify">
        <el-col :span="12" class="table_Box">
          <el-table :data="tableTest1" stripe style="width: 100%" class="left_table">
            <el-table-column align="center" label="已选择企业">
              <template slot-scope="scope">
                <div class="selectedCompony">
                  <el-col :span="16">{{scope.row.id}}</el-col>
                  <el-col :span="8">
                    <span class="remove" @click="leftHandleRemove(scope.row)">删除</span>
                  </el-col>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12" class="table_Box">
          <el-row class="tabletitle">
            <div class="selectCol">
              <span>企业名称</span>
              <el-input v-model="companyName" placeholder="请输入内容"></el-input>
            </div>
            <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
            <el-button @click="addGird" class="checkOutBtn">重置</el-button>
          </el-row>
          <el-row class="right_table">
            <el-table
              :data="tableTest2"
              ref="multipleTable"
              stripe
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column align="center" prop="id" label="序号"></el-table-column>
              <el-table-column align="center" prop="id" label="企业名称"></el-table-column>
            </el-table>
          </el-row>
        </el-col>
      </el-row>-->
      <el-row class="csRow">
        <el-transfer
          filterable
          filter-placeholder="请输入企业信息"
          v-model="bindCompany"
          :titles="['企业列表','已选择企业']"
          :button-texts="['移除', '添加']"
          :props="{key:'id',label:'nickname'}"
          :data="data"
        ></el-transfer>
      </el-row>
      <el-row class="footerBox leftFooter" style="margin-top:30px">
        <el-button @click="bindCompanySubmit " class="checkOutBtn footerBtnleft">确定</el-button>
        <el-button @click="dialogbinding=false" class="checkOutBtn footerBtnleft">取消</el-button>
      </el-row>
    </el-dialog>
    <!-- 绑定企业 -->

    <!-- 查看区域网格 -->
    <el-dialog
      :title="`查看区域网格`"
      class="riskDia"
      :visible.sync="dialogCheckout"
      width="60%"
      v-if="dialogCheckout"
      :before-close="dialogCheckoutClose"
      @close="dialogCheckoutClosed"
      @open="open"
    >
      <!-- :destroy-on-close="true" -->
      <el-row class="tableRowModify">
        <!-- v-if="dialogCheckout" -->
        <!--  ZL  -->
        <!-- 地图 -->
        <!-- <MAP2 @lonlag="aaa" :zl="zl"></MAP2> -->
        <SelectMap ref="SelectMap" :zl="zl"></SelectMap>
      </el-row>
      <!-- <el-row class="footerBox">
        <el-button @click="handleSign" class="checkOutBtn footerBtnleft">提交</el-button>
      </el-row>
      <el-form>
        <el-form-item label>
          <el-input v-model="lonlag1"></el-input>
        </el-form-item>
      </el-form>-->
    </el-dialog>
    <!-- 查看区域网格 -->

    <!-- 绘制区域网格 -->
    <el-dialog
      :title="`绘制区域网格`"
      class="riskDia"
      :visible.sync="dialogDraw"
      width="60%"
      v-if="dialogDraw"
      @close="dialogCheckoutClosed"
      :before-close="dialogDrawClose"
    >
      <el-row class="tableRowModify">
        <!-- ZL -->
        <Map :zl="zl"></Map>
      </el-row>
      <!-- <el-row class="footerBox"> -->
        <el-button @click="dialogDrawClose" class="checkOutBtn footerBtnleft" style="margin-left: 80%;">完成</el-button>
    <!--  </el-row> -->
      <!-- <el-form>
        <el-form-item label="经纬度">
          <el-input v-model="lonlag2"></el-input>
        </el-form-item>
      </el-form>-->
    </el-dialog>
    <!-- 绘制区域网格 -->
  </div>
</template>
<script>
// zl
import Map from '../manage/amap/index.vue'
import MAP2 from '../manage/amap/index2.vue'
import MapPosition from '../manage/amap/index_position.vue'
import allAreas from '../manage/amap/allAreas.vue'
import SelectMap from '../manage/amap/index2_detail.vue'
export default {
  //ZL
  components: {
    Map,
    MAP2: MAP2,
    MapPosition,
    allAreas,
    SelectMap,
  },
  data() {
    // 穿梭框
    const generateData = (_) => {
      const data = []
      const cities = [
        '邦泰合力1',
        '邦泰合力2',
        '邦泰合力3',
        '邦泰合力4',
        '邦泰合力5',
        '邦泰合力6',
        '邦泰合力7',
      ]
      const pinyin = [
        'shanghai',
        'beijing',
        'guangzhou',
        'shenzhen',
        'nanjing',
        'xian',
        'chengdu',
      ]
      cities.forEach((city, index) => {
        data.push({
          label: city,
          key: index,
          // pinyin: pinyin[index],
        })
      })
      return data
    }
    return {
      // 绑定区域网格id
      bindGridId: '',
      // 绑定的企业
      bindCompany: [],
      companyList: [],
      griddetail: {},
      zl: '',
      lonlag1: '', //经纬度
      lonlag2: '', //经纬度
      lonlag3: '',
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 添加
      dialogDetail: false,
      //查看

      selectDetail: false,
      // 定位(添加)
      dialogPosition: false,
      // 绑定企业
      dialogbinding: false,
      // 查看区域网格
      dialogCheckout: false,
      // 绘制区域网格
      dialogDraw: false,
      dangerName: '',
      formData: {
        name: '',
      },
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '低风险',
        },
        {
          value: 2,
          label: '一般风险',
        },
        {
          value: 3,
          label: '较大风险',
        },
        {
          value: 4,
          label: '重大风险',
        },
      ],
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      tableData: [],
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '低风险',
        },
        {
          value: 2,
          label: '一般风险',
        },
        {
          value: 3,
          label: '较大风险',
        },
        {
          value: 4,
          label: '重大风险',
        },
      ],
      tableTest1: [
        {
          id: '邦泰合力1 ',
        },
        {
          id: '邦泰合力2 ',
        },
        {
          id: '邦泰合力3 ',
        },
        {
          id: '邦泰合力4 ',
        },
        {
          id: '邦泰合力5 ',
        },
        {
          id: '邦泰合力6 ',
        },
        {
          id: '邦泰合力7 ',
        },
        {
          id: '邦泰合力8 ',
        },
        {
          id: '邦泰合力9 ',
        },
        {
          id: '邦泰合力10',
        },
        {
          id: '邦泰合力11',
        },
        {
          id: '邦泰合力12',
        },
        {
          id: '邦泰合力13',
        },
        {
          id: '邦泰合力14',
        },
      ],
      tableTest2: [
        {
          id: '邦泰合力001 ',
        },
        {
          id: '邦泰合力002 ',
        },
        {
          id: '邦泰合力003 ',
        },
        {
          id: '邦泰合力004 ',
        },
        {
          id: '邦泰合力005 ',
        },
        {
          id: '邦泰合力006 ',
        },
        {
          id: '邦泰合力007 ',
        },
        {
          id: '邦泰合力008 ',
        },
        {
          id: '邦泰合力009 ',
        },
        {
          id: '邦泰合力0010',
        },
        {
          id: '邦泰合力0011',
        },
        {
          id: '邦泰合力0012',
        },
        {
          id: '邦泰合力0013',
        },
        {
          id: '邦泰合力0014',
        },
      ],
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      tableData: [],
      addForm: {
        areaName: '',
        x: '',
        y: '',
        remark: '',
      },
      grid_id: '',
    }
  },

  methods: {
    //经纬度
    aaa(data) {
      console.log(data)
      this.lonlag1 = data.value
    },
    open() {
      console.log(111)
      //this.$refs.aMap.setMarker()
    },
    // bbb(data){
    //   console.log(data)
    //   this.lonlag2=data.value
    // },
    position(data) {
      console.log(data)
      this.addForm.x = data.lon
      this.addForm.y = data.lag
      this.lonlag3 = data.value
    },
    // 点击删除
    async handleRemove(row) {
      this.grid_id = row.id
      console.log(this.grid_id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('id', this.grid_id)

        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/RegionalGrid/del',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        console.log(res)
        this.getDangerList()
      }
    },
    // 点击绘制区域网格
    async handleDraw(row) {
      this.grid_id = row.id
      this.zl = row.id
      console.log('重绘' + this.grid_id)

      this.dialogDraw = true
    },
    // 点击查看区域网格
    async handleCheckout(row) {
      this.grid_id = row.id
      this.zl = row.id
      console.log(this.grid_id)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.grid_id)

      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log(res)
      this.dialogCheckout = true
    },
    // *绑定企业提交
    async bindCompanySubmit() {
      let bindStr = ''
      this.bindCompany.forEach((item) => {
        bindStr += item + ','
      })
      console.log('绑定企业id提交', bindStr)
      bindStr = bindStr.slice(0, bindStr.length - 1)
      console.log('jiequ', bindStr)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.bindGridId)
      param.append('type', 2)
      param.append('company_list', bindStr)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/with_company',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('绑定成功')
      this.dialogbinding = false
    },
    // 点击绑定企业
    async handleBinding(row) {
      this.bindGridId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/detail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.griddetail = res.datas
      console.log('网格详情', this.griddetail)
      if (res.datas.with_company) {
        this.bindCompany = res.datas.with_company.split(',')
      }
      this.dialogbinding = true
    },
    // 点击定位
    handlePosition() {
      this.dialogPosition = true
    },
    // 点击筛选
    handleSearch() {
      this.getDangerList()
    },
    handleSign() {
      this.getDangerList()
    },
    // async getDangerList() {
    //   let param = new URLSearchParams()
    //   param.append('access_token', this.token)
    //   param.append('page', this.currentPage)
    //   param.append('length', this.pageSize)
    //   param.append('company_name', this.companyName)
    //   param.append('danger_name', this.danName)
    //   const {
    //     data: res
    //   } = await this.$axios({
    //     method: 'post',
    //     url: '/api/danger_investigation/index',
    //     data: param,
    //   })
    //   if (res.code != 200) return this.$message.error(res.datas.msg)
    //   console.log(res)
    //   this.tableData = res.datas.list
    //   this.totalCount = parseInt(res.datas.count)
    // },
    async getDangerList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/index',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      console.log(res)
      console.log(res.datas.list)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
    },
    // *获取企业列表
    async getCompanyList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/company_all',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.companyList = res.datas
      this.data = res.datas
      console.log('企业列表', this.data)
    },
    async addGird(row) {
      this.dialogDetail = true
    },
    async selectGird(row) {
      this.selectDetail = true
    },
    async checkOut(row) {
      this.dialogDetail = true
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    //清除
    handleClearSubmit() {
      this.addForm.areaName = ''
      this.addForm.remark = ''
      this.addForm.x = ''
      this.addForm.y = ''
    },
    //提交
    async Position_submit() {
      //alert('区域网格名称'+this.addForm.areaName+'经度'+this.addForm.x+'维度'+this.addForm.y+'备注'+this.addForm.remark)
      // areaName: '',
      // x: '',
      // y: '',
      // remark: '',
      console.log(this.addForm.areaName)
      console.log(this.addForm.remark)
      console.log(this.addForm.x)
      console.log(this.addForm.y)
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('grid_name', this.addForm.areaName)
      param.append('remarks', this.addForm.remark)
      param.append('lng', this.addForm.x)
      param.append('lat', this.addForm.y)
      if (this.addForm.areaName == '') {
        this.$message('请添加区域网格名称')
        return false
      }
      if (this.addForm.x == '') {
        this.$message('请添加经度')
        return false
      }
      if (this.addForm.y == '') {
        this.$message('请添加纬度')
        return false
      }
      this.dialogDetailClose()
      const { data: res } = await this.$axios({
        method: 'post',
        // url: 'http://anquan.sxpz.vip/GovApi/RegionalGrid/add',
        url: '/api/RegionalGrid/add',
        data: param,
      })
      // 如果向服务器提交失败
      if (res.code != 200) return this.$message.error(res.datas.msg)
      //this.$message.error(res.datas.msg)
      console.log(res)
      this.getDangerList()
    },
    // 定位
    dialogPositionClose() {
      this.dialogPosition = false
    },
    // 绑定企业
    dialogbindingClose() {
      this.dialogbinding = false
    },
    dialogbindingClosed() {
      this.bindCompany = []
      this.bindGridId = ''
    },
    // 查看区域网格
    dialogCheckoutClose() {
      this.dialogCheckout = false
      this.getDangerList()
    },
    dialogCheckoutClosed() {
      this.zl = []
    },
    // 绘制区域网格
    dialogDrawClose() {
      this.dialogDraw = false
      this.getDangerList()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getDangerList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getDangerList()
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getCompanyList()
    this.getDangerList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    height: 70vh;
  }
  .tableRowModify2 {
    padding: 0 10px;
    height: 20vh;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}


/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.tableRowModify {
  width: 100%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
</style>
