<template>
  <div class="index-map">
    <!-- <div class="func-line">
      <div>
        <p>绘制多边形操作</p>
        <p>
          <span>
            选择边框颜色：
            <el-color-picker v-model="polyType.borderColor" size="mini"></el-color-picker>
          </span>
          <span>
            选择背景颜色：
            <el-color-picker v-model="polyType.fillColor" size="mini" :predefine="predefineColors"><span style="background-color: #edf033;" @click="color1()">123</span>
            <span style="background-color: #0055ff;" @click="color2()">123</span>
            <span style="background-color: #f00000;" @click="color3()">123</span>
            <span style="background-color: #ffae0c;" @click="color4()">123</span>
            </el-color-picker>
          </span>
        </p>
        <p>
          <span>
            背景透明度：
            <el-slider
              v-model="polyType.fillOpacity"
              :step="10"
              show-stops
              size="mini"
              style="width:200px;display:inline-block;vertical-align: middle;"
            ></el-slider>
            <span>{{polyType.fillOpacity + '%'}}</span>
          </span>
        </p>

        <p>
          <el-button @click="drawPolygon">开始绘制</el-button>
          <el-button @click="clearPolygon">清空当前绘制</el-button>
          <el-button @click="savePolygon">清空全部</el-button>
          <el-button @click="closeDrawPolygon">结束绘制并保存</el-button>
          <span style="font-size:10px;">小提示：右键取消</span>
        </p>
      </div>
      <div>
        <p>用保存的数据重新绘制多边形并添加事件</p>
        <el-button @click="resetDraw">重新绘制</el-button>
      </div>
    </div> -->
    <div class="map-select-box">
      <SelectMap ref="SelectMap"></SelectMap>
    </div>
  </div>
</template>
<script>
import SelectMap from "./allAreas_Detial.vue";
export default {
  data() {
    return {
      predefineColors: [
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                'rgba(255, 69, 0, 0.68)',
                'rgb(255, 120, 0)',
                'hsv(51, 100, 98)',
                'hsva(120, 40, 94, 0.5)',
                'hsl(181, 100%, 37%)',
                'hsla(209, 100%, 56%, 0.73)',
                '#c7158577'
              ],
      // 多边形绘制颜色选择
      polyType: {
        borderColor: "",
        fillOpacity: 0,
        fillColor: "#fff"
      }
    };
  },
  components: {
    SelectMap
  },
  methods: {
    color1(){
      this.polyType.fillColor='#edf033';
    },
    color2(){
      this.polyType.fillColor='#0055ff';
    },
    color3(){
      this.polyType.fillColor='#f00000';
    },
    color4(){
      this.polyType.fillColor='#ffae0c';
    },
    // 绘制多边形
    drawPolygon() {
      this.$refs["SelectMap"].polyType = this.polyType;
      this.$refs["SelectMap"].drawPolygon();
    },
    // 清空绘制
    clearPolygon() {
      this.$refs["SelectMap"].clearPolygon();
    },
    // 结束绘制
    closeDrawPolygon() {
      this.$refs["SelectMap"].closeDrawPolygon();
    },
    // 重绘多边形内容并添加事件
    resetDraw(){
      this.$refs["SelectMap"].resetDraw();
    }
  }
};
</script>
<style lang="scss" scoped>
.index-map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  .func-line {
    width: 100%;
    margin-bottom: 20px;
    > div {
      border: 1px solid #ebebeb;
      padding: 10px;
    }
  }
  .map-select-box {
    height: 520px;
    width: 100%;
  }
}
</style>
