<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>执法计划</span>
    </el-row>
    <el-row class="selectRow">
      <!-- <div class="selectCol">
        <span>风险分级</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>-->
      <!-- <div class="selectCol">
        <span>执法名称</span>
        <el-select clearable v-model="platId" placeholder="请选择">
          <el-option
            v-for="item in platList"
            :key="item.id"
            :label="item.enforce_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>-->
      <div class="selectCol">
        <span>执法名称</span>
        <el-input v-model="searchEnforce_name" placeholder="请输入内容"></el-input>
      </div>
      <!-- <div class="selectCol">
        <span>风险点名称</span>
        <el-input v-model="danName" placeholder="请输入内容"></el-input>
      </div>-->
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
      <el-button @click="handleAdd" class="checkOutBtn">新增</el-button>
      <el-button @click="handleDeleteSome" style="background-color:#fb0103" class="checkOutBtn">批量删除</el-button>
    </el-row>
    <el-row class="tableRow">
      <el-table
        @selection-change="handleSelectionChange"
        border
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
        <el-table-column align="center" prop="enforce_name" label="执法名称" width="200">
          <template slot-scope="scope">
            <span :title="scope.row.enforce_name" class="overflowDoit">{{scope.row.enforce_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="enforce_content" label="执法内容">
          <template slot-scope="scope">
            <span
              :title="scope.row.enforce_content"
              class="overflowDoit"
            >{{scope.row.enforce_content}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="enforce_time" label="执法日期" width="100">
          <template slot-scope="scope">
            <span :title="scope.row.enforce_time" class="overflowDoit">{{scope.row.enforce_time}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="组织人">
          <template slot-scope="scope">
            <span :title="scope.row.organizer" class="overflowDoit">{{scope.row.organizer}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="持续时长" width="120">
          <template slot-scope="scope">
            <span :title="scope.row.duration" class="overflowDoit">{{scope.row.duration}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="220">
          <template slot-scope="scope">
            <el-button @click="checkEdit(scope.row)" class="tableBtn">编辑</el-button>
            <el-button
              @click="handleRemove(scope.row)"
              class="tableBtn"
              style="background-color:#fb0103"
            >删除</el-button>
            <el-button @click="checkOut(scope.row)" class="tableBtn">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>
    <!-- 执法计划详情 -->
    <el-dialog
      :title="`执法计划详情`"
      class="riskDia"
      :visible.sync="dialogDetail"
      width="40%"
      :before-close="dialogDetailClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="formData" label-width="160px">
          <el-form-item label="执法名称">
            <el-input style="width:70%" disabled v-model="formData.enforce_name"></el-input>
          </el-form-item>
          <el-form-item label="执法内容">
            <el-input style="width:70%" disabled v-model="formData.enforce_content"></el-input>
          </el-form-item>
          <el-form-item label="执法日期">
            <el-input style="width:70%" disabled v-model="formData.enforce_time"></el-input>
          </el-form-item>
          <el-form-item label="组织人">
            <el-input style="width:70%" disabled v-model="formData.organizer"></el-input>
          </el-form-item>
          <el-form-item label="持续时长">
            <el-input style="width:70%" disabled v-model="formData.duration"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </el-dialog>
    <!-- 执法计划详情 -->
    <!-- 执法计划编辑 -->
    <el-dialog
      :title="`编辑执法计划`"
      class="riskDia"
      :visible.sync="dialogEdit"
      width="40%"
      :before-close="dialogEditClose"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="editData" label-width="160px">
          <el-form-item label="执法名称">
            <!-- <el-select clearable v-model="platId" placeholder="请选择">
              <el-option
                v-for="item in platList"
                :key="item.id"
                :label="item.enforce_name"
                :value="item.id"
              ></el-option>
            </el-select>-->
            <el-input style="width:70%" v-model="editData.enforce_name"></el-input>
          </el-form-item>
          <el-form-item label="执法内容">
            <el-input style="width:70%" v-model="editData.enforce_content"></el-input>
          </el-form-item>
          <el-form-item label="执法日期">
            <el-date-picker
              v-model="editData.enforce_time"
              type="date"
              style="width:70%"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="组织人">
            <el-input style="width:70%" v-model="editData.organizer"></el-input>
          </el-form-item>
          <el-form-item label="持续时长">
            <el-input style="width:70%" v-model="editData.duration"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEdit = false">取 消</el-button>
        <el-button type="primary" @click="editFormSubmit">保存</el-button>
      </span>
    </el-dialog>
    <!-- 执法计划编辑 -->
    <!-- 执法计划新增 -->
    <el-dialog
      :title="`新增执法计划`"
      class="riskDia"
      :visible.sync="addDialog"
      width="40%"
      :before-close="addDialogClose"
      @close="addDialogClosed"
    >
      <el-row class="tableRow">
        <el-form ref="form" :model="addData" label-width="160px">
          <el-form-item label="执法名称">
            <el-input style="width:70%" v-model="addData.enforce_name"></el-input>
          </el-form-item>
          <el-form-item label="执法内容">
            <el-input style="width:70%" v-model="addData.enforce_content"></el-input>
          </el-form-item>
          <el-form-item label="执法日期">
            <el-date-picker
              v-model="addData.enforce_time"
              type="date"
              style="width:70%"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="组织人">
            <el-input style="width:70%" v-model="addData.organizer"></el-input>
          </el-form-item>
          <el-form-item label="持续时长">
            <el-input style="width:70%" v-model="addData.duration"></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFormSubmit">保存</el-button>
      </span>
    </el-dialog>
    <!-- 执法计划新增 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      ids: [],
      searchEnforce_name: '',
      platId: '',
      platList: [],
      userName: '',
      token: '',
      danName: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      dialogDetail: false,
      dialogEdit: false,
      addDialog: false,
      dangerName: '',
      formData: {},
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 1,
          label: '低风险',
        },
        {
          value: 2,
          label: '一般风险',
        },
        {
          value: 3,
          label: '较大风险',
        },
        {
          value: 4,
          label: '重大风险',
        },
      ],
      value: '',
      startTime: '',
      currentPage: 1,
      totalCount: 0,
      pageSize: 15,
      tableData: [],
      editData: {},
      addData: {
        enforce_name: '',
        enforce_content: '',
        enforce_time: '',
        organizer: '',
        duration: '',
      },
      editId: '',
    }
  },
  methods: {
    handleSearch() {
      this.currentPage = 1
      this.getRiskList()
    },
    async getRiskList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('enforce_name', this.searchEnforce_name)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Enforce/planList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
      console.log('风险点辨识数据', this.tableData)
      console.log('风险点辨识数据条数', this.totalCount)
    },
    // *点击删除
    // *批量删除
    async handleDeleteSome() {
      const isDelete = await this.$confirm('是否批量删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消批量删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', this.ids)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Enforce/planDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getRiskList()
      }
    },
    // *删除
    async handleRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/Enforce/planDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getRiskList()
      }
    },
    // *点击新增
    handleAdd() {
      this.addDialog = true
    },
    // *新增提交
    async addFormSubmit() {
      if (this.addData.enforce_name.trim().length == 0)
        return this.$message.error('请填写执法名称')
      if (this.addData.enforce_content.trim().length == 0)
        return this.$message.error('请填写执法内容')
      if (!this.addData.enforce_time)
        return this.$message.error('请选择执法日期')
      if (this.addData.organizer.trim().length == 0)
        return this.$message.error('请填写组织人')
      if (this.addData.duration.trim().length == 0)
        return this.$message.error('请填写持续时长')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('enforce_name', this.addData.enforce_name)
      param.append('enforce_content', this.addData.enforce_content)
      param.append('enforce_time', this.addData.enforce_time)
      param.append('organizer', this.addData.organizer)
      param.append('duration', this.addData.duration)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Enforce/planAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editData = res.datas
      this.addDialog = false
      this.getRiskList()
    },
    // todo编辑提交
    async editFormSubmit() {
      if (this.editData.enforce_name.trim().length == 0)
        return this.$message.error('请填写执法名称')
      if (this.editData.enforce_content.trim().length == 0)
        return this.$message.error('请填写执法内容')
      if (!this.editData.enforce_time)
        return this.$message.error('请选择执法日期')
      if (this.editData.organizer.trim().length == 0)
        return this.$message.error('请填写组织人')
      if (this.editData.duration.trim().length == 0)
        return this.$message.error('请填写持续时长')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      param.append('enforce_name', this.editData.enforce_name)
      param.append('enforce_content', this.editData.enforce_content)
      param.append('enforce_time', this.editData.enforce_time)
      param.append('organizer', this.editData.organizer)
      param.append('duration', this.editData.duration)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Enforce/planEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editData = res.datas
      this.dialogEdit = false
      this.getRiskList()
    },
    // *点击编辑按钮
    async checkEdit(row) {
      this.editId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Enforce/planDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.editData = res.datas
      this.dialogEdit = true
    },
    async checkOut(row) {
      this.dangerName = row.danger_name
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', row.id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Enforce/planDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.formData = res.datas
      this.dialogDetail = true
    },
    // 批量删除选中状态
    handleSelectionChange(selection) {
      let arr = []
      selection.forEach((item) => {
        arr.push(item.id)
      })
      this.ids = [...arr]
    },
    dialogDetailClose() {
      this.dialogDetail = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getRiskList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getRiskList()
    },
    dialogEditClose() {
      this.dialogEdit = false
    },
    addDialogClose() {
      this.addDialog = false
    },
    addDialogClosed() {
      this.addData = {
        enforce_name: '',
        enforce_content: '',
        enforce_time: '',
        organizer: '',
        duration: '',
      }
    },
    // 获取下拉框
    async getPlatList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Enforce/planLists',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.platList = res.datas
      this.platList.unshift({ id: '', enforce_name: '全部' })
      console.log('平台列表', res)
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    this.userName = localStorage.getItem('btlh_nickname') || ''
    // this.getPlatList()
    this.getRiskList()
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;
  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }
  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }
    .selectCol {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    /deep/ .el-input__icon {
      display: none !important;
    }
    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }
  // 表格
  .tableRow {
    padding: 0 10px;
  }
  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;
    td {
      padding: 5px 0;
    }
    td,
    th.is-leaf {
      border-bottom: 0;
    }
    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }
  .el-table::before {
    display: none;
  }
  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}
/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }
  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}
/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;
  .el-button:nth-child(1) {
    margin-left: 10px;
  }
  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }
  .el-button:nth-child(even) {
    width: 50%;
  }
  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}
.tableRow {
  padding: 0 10px;
  width: 100%;
}
/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  // border: 1px solid #eee;
  td {
    padding: 5px 0;
  }
  td,
  th.is-leaf {
    border-bottom: 1px solid #eee !important;
  }
  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }
  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}
.el-table::before {
  display: none;
}
// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }
  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}
.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__label {
    color: #000;
  }
  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}
// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
// 修改详情和编辑字体大小
/deep/ .el-form-item__label {
  font-size: 13px !important;
  color: #666 !important;
}
// 修改详情和编辑字体大小
/deep/ .el-input__inner,
/deep/.el-textarea__inner {
  font-size: 12px !important;
  color: #999 !important;
}

/deep/.el-table__empty-block {
  border-bottom: 1px solid #eee !important;
}
</style>