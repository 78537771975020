<template>
  <div class="handleLog">
    <el-row class="titleRow">
      <span class="line"></span>
      <span>企业政府端绑定</span>
    </el-row>
    <el-row class="selectRow">
      <div class="selectCol" style="width:200px">
        <span>企业名称</span>
        <el-input v-model="searchInfo.company_name" placeholder="请输入内容"></el-input>
      </div>
      <div class="selectCol" style="width:200px">
        <span>政府平台</span>
        <el-input v-model="searchInfo.gov_name" placeholder="请输入内容"></el-input>
      </div>
      <el-button @click="handleSearch" class="checkOutBtn">筛选</el-button>
      <el-button @click="handleadd" class="checkOutBtn">新增绑定</el-button>
      <el-button
        @click="handleDeleteSome"
        :disabled="isDisable"
        class="checkOutBtn"
        style="background-color:#fb0103"
      >批量删除</el-button>
    </el-row>

    <el-row class="tableRowModify">
      <el-table
        :data="tableData"
        stripe
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
        <el-table-column align="center" prop="company_name" label="企业名称">
          <template slot-scope="scope">
            <span :title="scope.row.company_name" class="overflowDoit">{{scope.row.company_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="350" label="政府平台">
          <template slot-scope="scope">
            <span :title="scope.row.gov_name" class="overflowDoit">{{scope.row.gov_name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" class="tableBtn">编辑</el-button>
            <el-button
              @click="handleRemove(scope.row)"
              class="tableBtn"
              style="background-color:#fb0103"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length>0"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[3, 5, 10, 15, 20]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
      ></el-pagination>
    </el-row>

    <!-- 新增 -->
    <el-dialog
      :title="`添加绑定`"
      class="riskDia"
      :visible.sync="dialogadd"
      width="50%"
      :before-close="dialogaddClose"
      @close="dialogaddClosed"
    >
      <el-row class="tableRowModify">
        <el-form ref="form" :model="addFormData" label-width="120px">
          <el-form-item label="企业名称" prop="desc" style="margin-bottom:30px">
            <el-select
              v-model="addForm.company_id"
              clearable
              filterable
              placeholder="请选择"
              style="width:80%"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="政府平台" prop="desc" style="margin-bottom:30px">
            <el-select
              v-model="addForm.gov_id"
              clearable
              filterable
              placeholder="请选择"
              style="width:80%"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="footerBox">
        <el-button @click="addFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="handleClearSubmit" class="checkOutBtn footerBtn">清空</el-button>
      </el-row>
    </el-dialog>
    <!-- 新增 -->

    <!-- 编辑 -->
    <el-dialog
      :title="`编辑绑定`"
      class="riskDia"
      :visible.sync="dialogedit"
      width="50%"
      :before-close="dialogeditClose"
      @close="dialogeditClosed"
    >
      <el-row class="tableRowModify">
        <el-form ref="form" :model="addFormData" label-width="120px">
          <el-form-item label="企业名称" prop="desc" style="margin-bottom:30px">
            <el-select
              v-model="modifyForm.company_id"
              clearable
              filterable
              placeholder="请选择"
              style="width:80%"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="政府平台" prop="desc" style="margin-bottom:30px">
            <el-select
              v-model="modifyForm.gov_id"
              clearable
              filterable
              placeholder="请选择"
              style="width:80%"
            >
              <el-option
                v-for="item in companyList"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="footerBox">
        <el-button @click="editFormSubmit" class="checkOutBtn footerBtn">提交</el-button>
        <el-button @click="handleClearSubmit1" class="checkOutBtn footerBtn">清空</el-button>
      </el-row>
    </el-dialog>
    <!-- 编辑 -->
  </div>
</template>
<script>
import qs from 'qs'
export default {
  data() {
    return {
      fileList: [],
      dialogaddV: false,

      videoUrl: '',
      // 图片上传
      imageUrl: '',
      editId: '',
      addFormData: {
        sort: '',
        name: '',
        url: '',
      },
      ids: [],
      // data: generateData(),
      data: [],
      userName: '',
      token: '',
      // 企业信息
      infoVisibale: false,
      companyName: '',
      danName: '',
      // 新增
      dialogadd: false,
      // 编辑
      dialogedit: false,
      dangerName: '',
      formData: {
        name: '',
      },
      searchInfo: {
        company_name: '',
        gov_name: '',
      },
      addForm: {
        company_id: '',
        gov_id: '',
      },
      modifyForm: {
        company_id: '',
        gov_id: '',
      },
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      tableData: [],
      currentPage: 1,
      totalCount: 0,
      pageSize: 15,
      tableData: [],
      companyList: [],
    }
  },

  methods: {
    handleSearch() {
      this.currentPage = 1
      this.getCarouselList()
    },
    // *获取企业列表
    async getCompanyList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      //param.append('company_name', this.companyName)
      //param.append('danger_name', this.danName)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/RegionalGrid/company_all',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.companyList = res.datas
      console.log('企业列表', this.companyList)
    },
    async beforeVideoUpload(file) {
      // return console.log(file)
      const isJPG = file.type === 'video/mp4'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传首页视频只允许 mp4 格式!')
        return new Promise((resolve, reject) => {
          this.$refs['addUpLoad'].validate((valid) => {
            if (valid) {
              return resolve(true)
            } else {
              return reject(false)
            }
          })
        })
      }
      // if (!isLt2M) {
      //   return this.$message.error('上传头像图片大小不能超过 2MB!')
      //   return new Promise((resolve, reject) => {
      //     this.$refs['addUpLoad'].validate((valid) => {
      //       if (valid) {
      //         return resolve(true)
      //       } else {
      //         return reject(false)
      //       }
      //     })
      //   })
      // }
      // return isJPG && isLt2M
      let param = new FormData()
      param.append('access_token', this.token)
      param.append('file', file)
      // return console.log('param', param.get('access_token'))
      const { data: res } = await this.$axios({
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/api/Homepage/uploadImg',
        // data: param,
        data: param,
      })
      // return console.log('res', res)
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success('上传成功')
      // this.imageUrl = res.datas.img_url
      this.videoUrl = res.datas.img_url
      // return new Promise((resolve, reject) => {
      //   this.$refs['addUpLoad'].validate((valid) => {
      //     if (valid) {
      //       return resolve(true)
      //     } else {
      //       return reject(false)
      //     }
      //   })
      // })
      // return false
      console.log(this.videoUrl)
    },
    // 首页视频提交
    async addVideoSubmit() {
      let param = new URLSearchParams()
      if (!this.videoUrl) {
        this.$message.error('请上传视频')
      }
      param.append('access_token', this.token)
      param.append('url', this.videoUrl)
      param.append('style', 2)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/Homepage/add',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)

      this.dialogaddV = false
      this.getCarouselList()
    },
    // *批量删除
    async handleDeleteSome() {
      const isDelete = await this.$confirm('是否批量删除', {                                                                                                                                                                                                                                      
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消批量删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', this.ids)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/CompanyBing/BingDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getCarouselList()
      }
    },
    // *删除
    async handleRemove(row) {
      const delArr = []
      delArr.push(row.id)
      const isDelete = await this.$confirm('是否删除', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
      }).catch((error) => error)
      // 如果用户点记得是取消操作
      if (isDelete === 'cancel') return this.$message('取消删除操作')
      // 如果用户点击的是删除
      if (isDelete === 'confirm') {
        // 发送删除请求
        let param = new URLSearchParams()
        param.append('access_token', this.token)
        param.append('ids', delArr)
        const { data: res } = await this.$axios({
          method: 'post',
          url: '/api/CompanyBing/BingDel',
          data: param,
        })
        if (res.code != 200) return this.$message.error(res.datas.msg)
        this.$message.success(res.datas.msg)
        this.getCarouselList()
      }
    },
    // *编辑
    async handleEdit(row) {
      this.editId = row.id
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/CompanyBing/BingDetail',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.modifyForm = res.datas
      console.log('编辑', this.modifyForm)
      this.dialogedit = true
    },
    // *编辑提交
    async editFormSubmit() {
      if (!this.modifyForm.company_id)
        return this.$message.error('请选择政府平台')
      if (!this.modifyForm.gov_id) return this.$message.error('请选择企业名称')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('id', this.editId)
      param.append('company_id', this.modifyForm.company_id)
      param.append('gov_id', this.modifyForm.gov_id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/CompanyBing/BingEdit',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)

      this.dialogedit = false
      this.getCarouselList()
    },
    // *新增提交
    async addFormSubmit() {
      // console.log(this.addForm)
      // return
      if (!this.addForm.company_id) return this.$message.error('请选择政府平台')
      if (!this.addForm.gov_id) return this.$message.error('请选择企业名称')
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('gov_id', this.addForm.gov_id)
      param.append('company_id', this.addForm.company_id)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/CompanyBing/BingAdd',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.$message.success(res.datas.msg)

      this.dialogadd = false
      this.getCarouselList()
    },
    // *新增
    handleadd() {
      this.dialogadd = true
    },

    handleSelectionChange(selection) {
      let arr = []
      selection.forEach((item) => {
        arr.push(item.id)
      })
      this.ids = [...arr]
    },
    // 获取列表
    async getCarouselList() {
      let param = new URLSearchParams()
      param.append('access_token', this.token)
      param.append('page', this.currentPage)
      param.append('length', this.pageSize)
      param.append('company_name', this.searchInfo.company_name)
      param.append('gov_name', this.searchInfo.gov_name)
      const { data: res } = await this.$axios({
        method: 'post',
        url: '/api/CompanyBing/BingList',
        data: param,
      })
      if (res.code != 200) return this.$message.error(res.datas.msg)
      this.tableData = res.datas.list
      this.totalCount = parseInt(res.datas.count)
      console.log('绑定企业链接', this.tableData)
      this.totalCount = parseInt(res.datas.count)
    },
    // 添加
    dialogDetailClose() {
      this.dialogDetail = false
    },
    selectDetailClose() {
      this.selectDetail = false
    },
    // 富文本
    onEditorBlur(editor) {
      //失去焦点事件
    },
    onEditorFocus(editor) {
      //获得焦点事件
    },
    onEditorChange({ editor, html, text }) {
      //编辑器文本发生变化
      //this.content可以实时获取到当前编辑器内的文本内容
      // console.log(this.content);
    },
    //清除
    handleClearSubmit() {
      this.addForm.company_id = ''
      this.addForm.gov_id = ''
    },
    //清除
    handleClearSubmit1() {
      this.modifyForm = {}
    },
    handleClearSubmit2() {
      this.videoUrl = ''
    },
    // 新增
    dialogaddClose() {
      this.dialogadd = false
    },
    dialogaddClosed() {
      this.addForm.company_id = ''
      this.addForm.gov_id = ''
    },
    // 编辑
    dialogeditClose() {
      this.dialogedit = false
    },
    dialogeditClosed() {
      this.editId = ''
      this.modifyForm = {}
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getCarouselList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getCarouselList()
    },

    dialogaddVClose() {
      this.dialogaddV = false
    },
    dialogaddvClosed() {
      this.videoUrl = ''
    },
  },
  created: function () {
    this.token = localStorage.getItem('btlh_token') || ''
    // this.myHeaders.Authorization = this.token
    this.userName = localStorage.getItem('btlh_nickname') || ''
    this.getCarouselList()
    this.getCompanyList()
  },
  computed: {
    isDisable() {
      if (this.ids.length > 0) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang="less" scoped>
.handleLog {
  padding: 20px;
  background-color: #fff;

  // 标题行
  .titleRow {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;

    .line {
      display: block;
      width: 4px;
      height: 22px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  // 企业选择行
  .selectRow {
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;

    .el-date-editor.el-input,
    .el-input {
      width: 68%;
    }

    .selectCol {
      margin-right: 10px;
    }

    /deep/ .el-input__inner {
      font-size: 10px;
      height: 26px;
      width: 120px;
      border-radius: 0;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    /deep/ .el-input__icon {
      display: none !important;
    }

    .checkOutBtn {
      font-size: 12px;
      padding: 5px 20px;
      background-color: #447ed9;
      color: #fff;
    }
  }

  // 表格
  .tableRowModify {
    padding: 0 10px;
    max-height: 70vh;
  }

  /deep/ .el-table {
    margin-top: 0;
    font-size: 12px;

    td {
      padding: 5px 0;
    }

    td,
    th.is-leaf {
      border-bottom: 1px solid #eee;
    }

    th {
      background-color: #f7f7f7;
      color: #222;
      font-weight: 700;
    }

    .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #f7f7f7;
    }
  }

  .el-table::before {
    display: none;
  }

  // 分页
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.tableBtn {
  font-size: 12px;
  padding: 5px 10px;
  background-color: #447ed9;
  color: #fff;
}

/deep/ .el-dialog__header {
  .el-dialog__title {
    font-size: 14px;
  }

  // logo
  background: url(../../assets/images/smile.png) no-repeat;
  background-size: 20px;
  background-position: 35px 22px;
  padding-left: 65px;
}

/deep/ .el-dialog__body {
  display: flex;
  flex-wrap: wrap;
  padding-left: 60px;

  .el-button:nth-child(1) {
    margin-left: 10px;
  }

  .el-button:nth-child(odd) {
    width: 30%;
    margin-right: 6%;
  }

  .el-button:nth-child(even) {
    width: 50%;
  }

  .el-button {
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    padding: 10px 0px;
    background-color: #447ed9;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
  }
}

.tableRowModify {
  padding: 0 10px;
  width: 100%;
}

/deep/ .el-table {
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #eee;

  td {
    padding: 5px 0;
  }

  td,
  th.is-leaf {
    border-bottom: 0;
  }

  th {
    background-color: #f7f7f7;
    color: #222;
    font-weight: 700;
  }

  .el-table--striped .el-table__body tr.el-table__row--striped td {
    background-color: #f7f7f7;
  }
}

.el-table::before {
  display: none;
}

// 分页
.el-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.riskDia {
  .tableBtnRisk {
    width: 100px !important;
    padding: 5px 10px !important;
    margin: 0 !important;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px 30px;
  }

  /deep/ .el-dialog__header {
    .el-dialog__title {
      font-size: 16px;
    }
  }
}

.riskDia {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__label {
    color: #000;
  }

  /deep/ .el-input__inner {
    height: 30px;
    border-radius: 0;
  }

  /deep/ .modifyInner textarea {
    border-radius: 0 !important;
    width: 80%;
    height: 70px;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

/deep/ .el-input.is-disabled .el-input__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #333 !important;
  background-color: #fff !important;
  overflow: auto;
  text-overflow: ellipsis;
}

.footerBox {
  display: flex;
  margin-left: 120px;
  margin-bottom: 80px;
}

.footerBtn {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.footerBtnleft {
  width: 100px !important;
  height: 30px;
  display: flex;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
}

.dingwei {
  position: absolute;
  top: 5px;
  left: 23%;
}

.tableRowModify {
  width: 100%;
}

.leftFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  margin-right: 30px;
}

.selectedCompony {
  .remove {
    cursor: pointer;
  }
}

.tabletitle {
  display: flex;

  .selectCol {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      margin-left: 10px;
      width: 90px;
    }

    /deep/ .el-input__inner {
      height: 25px !important;
      width: 150px;
    }
  }

  .checkOutBtn {
    width: 80px !important;
    margin-bottom: 0;
    height: 25px;
    display: flex;
    padding: 0;
    justify-content: center;
  }
}

.table_Box {
  height: 500px;
  overflow: auto;
}

.left_table {
  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.right_table {
  margin-top: 9px;

  /deep/ th {
    padding: 0;
    height: 33px;
  }
}

.csRow {
  width: 100%;

  /deep/.el-transfer-panel {
    width: 40%;
  }

  /deep/ .el-transfer__buttons {
    width: 80px;
  }

  /deep/ .el-button {
    width: 50px !important;

    i {
      display: none;
    }
  }

  /deep/ .el-input {
    width: 90% !important;
  }
}
/deep/ .quill-editor {
  padding: 0;
}
// 图片上传
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .avatar-uploader-margin {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 120px;
}
video,
video:hover,
video:active,
video:visited,
video:link,
video:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  -webkit-tap-highlight-color: transparent;

  outline: none;

  background: none;

  text-decoration: none;
}
.modifyBox {
  margin-top: 40px;
  margin-left: 10px !important;
}

// 超出...的容器
.overflowDoit {
  width: 100%;
  white-space: nowrap; //不换行
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //变成...
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type='number'] {
  height: 1000px;
  -moz-appearance: textfield !important;
}
</style>
