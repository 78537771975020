import { render, staticRenderFns } from "./carousel.vue?vue&type=template&id=0ce6eaa5&scoped=true"
import script from "./carousel.vue?vue&type=script&lang=js"
export * from "./carousel.vue?vue&type=script&lang=js"
import style0 from "./carousel.vue?vue&type=style&index=0&id=0ce6eaa5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce6eaa5",
  null
  
)

export default component.exports